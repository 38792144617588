import actions from '../actions/experiences';

const initialState: any = {
    loading: false,
    publishWizardOpen: false,
    filters: {
        page: 1,
        items_per_page: 25,
        status: [],
        batch_id: '',
        experience_id: '',
        rendered_only: 0,
        order: 'date_created',
        order_direction: 'desc'
    },
    selected: {},
    data: {}
};

const experiencesList = (state = initialState, action): any => {
    let filtersCopy: any;

    switch (action.type) {
        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.toggle
            };
        case actions.FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters
                }
            };
        case actions.FILTER_LIST:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    rendered_only: action.errorFilter
                }
            };
        case actions.FILTER_INVENTORY:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [`inventory.${action.inventoryItem}`]: action.searchValue
                }
            };
        case actions.REMOVE_FILTER_INVENTORY:
            filtersCopy = { ...state.filters };
            delete filtersCopy[`inventory.${action.inventoryItem}`];

            return {
                ...state,
                filters: filtersCopy
            };
        case actions.CLEAR_FILTERS:
            return {
                ...state,
                filters: { ...initialState.filters }
            };
        case actions.SELECT:
            return {
                ...state,
                selected: { ...action.selectedExperience }
            };
        case actions.SEED_ID:
            return {
                ...state,
                selected: { id: action.experienceId }
            };
        case actions.GET:
            return {
                ...state,
                data: action.experiences
            };
        case actions.UPDATE_MODERATION_STATUS:
            return {
                ...state,
                selected:
                    state.selected.id === action.experienceId
                        ? { ...state.selected, moderation_status: action.status }
                        : state.selected,
                data: {
                    ...state.data,
                    experiences: state.data.experiences.map((exp: any) => {
                        if (exp.id === action.experienceId) {
                            return {
                                ...exp,
                                moderation_status: action.status
                            };
                        }

                        return exp;
                    })
                }
            };
        case actions.UPDATE_NOTES:
            return {
                ...state,
                selected:
                    state.selected.id === action.experienceId
                        ? { ...state.selected, notes: action.notes }
                        : state.selected,
                data: {
                    ...state.data,
                    experiences: state.data.experiences.map((exp: any) => {
                        if (exp.id === action.experienceId) {
                            return {
                                ...exp,
                                notes: action.notes
                            };
                        }

                        return exp;
                    })
                }
            };

        case actions.DELETE:
            return {
                ...state,
                selected: {},
                data: {
                    ...state.data,
                    experiences: state.data.experiences.filter(
                        (exp: any) => exp.id !== action.experienceId
                    )
                }
            };
        case actions.SET_WIZARD_OPEN:
            return {
                ...state,
                publishWizardOpen: action.toggle
            };
        default:
            return state;
    }
};

export default experiencesList;
