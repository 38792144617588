import * as React from 'react';
import hotkeys from 'hotkeys-js';
import OutputSelector from './OutputSelector';
import PreviewerFrame from './PreviewerFrame';
import ExperienceMetaFields from './ExperienceMetaFields';
import ExperienceVariables from './ExperienceVariables';
import PlaybackSettingsMenu from './PlaybackSettingsMenu';
import ReactResizeDetector from 'react-resize-detector';
import {
    Button,
    Section,
    StorageService,
    ImposiumDropdown,
    ButtonMenu,
    ButtonMenuItem,
    ShortcutMenu
} from '@imposium-hub/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons/faKeyboard';
import { faPhotoVideo } from '@fortawesome/free-solid-svg-icons/faPhotoVideo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    selectExperience,
    updateModerationStatus,
    deleteExperience,
    blockExperience
} from '../../redux/actions/experiences';
import { togglePreviewer } from '../../redux/actions/panes';
import { PREVIEWER_COPY } from '../../constants/copy';

interface IPreviewerProps {
    panes: any;
    experiencesList: any;
    selectExperience: (exp: any) => any;
    updateModerationStatus: (experienceId: string, status: string) => any;
    togglePreviewer: (toggle: boolean) => any;
    deleteExperience: (expId: string) => any;
    blockExperience: (expId: string) => any;
}

interface IPreviewerState {
    outputKeys: [string, string][];
    activeMedia: any;
    sectionWidth: number;
    mediaWidth: number;
    mediaHeight: number;
    showPlaybackSettings: boolean;
    showShortcutsMenu: boolean;
}

class Previewer extends React.Component<IPreviewerProps, IPreviewerState> {
    private static readonly TAB_CACHE_KEY: string = 'exp_manager_active_tab_keys';

    private readonly playbackSettingsToggleRef: any;

    private readonly shortcutsToggleRef: any;

    private readonly previewerRef: any;

    private readonly updateModerationOnKeyPress = (status: string) =>
        this.doUpdateModerationOnKeyPress(status);

    private dropdownMenuItems: any;

    constructor(props: IPreviewerProps) {
        super(props);

        this.state = {
            outputKeys: [],
            activeMedia: { mediaKey: '', type: '', src: '' },
            sectionWidth: 0,
            mediaWidth: 1920,
            mediaHeight: 1080,
            showPlaybackSettings: false,
            showShortcutsMenu: false
        };

        this.playbackSettingsToggleRef = React.createRef();
        this.shortcutsToggleRef = React.createRef();
        this.previewerRef = React.createRef();

        this.dropdownMenuItems = [
            <ButtonMenuItem
                key='delete'
                label={
                    <span>
                        {<FontAwesomeIcon icon={faTrash} />}&nbsp;{PREVIEWER_COPY.delete}
                    </span>
                }
                onClick={this.confirmDelete}
            />,
            <ButtonMenuItem
                key='block'
                label={
                    <span>
                        {<FontAwesomeIcon icon={faBan} />}&nbsp;{PREVIEWER_COPY.block}
                    </span>
                }
                onClick={this.confirmBlock}
            />
        ];
    }

    public componentDidMount = (): void => {
        this.flattenOutputKeys();

        hotkeys('P', () => this.updateModerationOnKeyPress('pending'));
        hotkeys('A', () => this.updateModerationOnKeyPress('approved'));
        hotkeys('U', () => this.updateModerationOnKeyPress('used'));
        hotkeys('V', () => this.updateModerationOnKeyPress('vip'));
        hotkeys('R', () => this.updateModerationOnKeyPress('rejected'));
    };

    public componentDidUpdate = (prevProps: IPreviewerProps): void => {
        const {
            experiencesList: { selected }
        } = this.props;

        if (selected !== prevProps.experiencesList.selected) {
            this.flattenOutputKeys(true);
        }
    };

    public componentWillUnmount = (): void => {
        hotkeys.unbind('P', () => this.updateModerationOnKeyPress('pending'));
        hotkeys.unbind('A', () => this.updateModerationOnKeyPress('approved'));
        hotkeys.unbind('U', () => this.updateModerationOnKeyPress('used'));
        hotkeys.unbind('V', () => this.updateModerationOnKeyPress('vip'));
        hotkeys.unbind('R', () => this.updateModerationOnKeyPress('rejected'));
    };

    private doUpdateModerationOnKeyPress = (status: string): void => {
        this.props.updateModerationStatus(this.props.experiencesList.selected.id, status);
    };

    private copySectionWidth = (w: number): void => this.setState({ sectionWidth: Math.floor(w) });

    private flattenOutputKeys = (update?): void => {
        const {
            experiencesList: {
                selected: { output }
            }
        } = this.props;

        if (!output || Object.keys(output).length === 0) {
            this.setState({
                outputKeys: [],
                activeMedia: { mediaKey: '', type: '', src: '' },
                mediaWidth: 1920,
                mediaHeight: 1080
            });
            return;
        }

        const outputKeys: any = Object.keys(output)
            .reverse()
            .reduce((prevState: any, typeKey: string) => {
                // Map type / media key to a tuple inside a list
                const mediaKeyTuples: [string, string][] = Object.keys(output[typeKey]).map(
                    (mediaKey: string) => [typeKey, mediaKey]
                );

                // Merge prev / next list
                return [...prevState, ...mediaKeyTuples];
            }, []);

        this.setState({ outputKeys }, () => {
            if (outputKeys.length > 0) {
                StorageService.getItem(Previewer.TAB_CACHE_KEY)
                    .then((cachedKeys: [string, string]) => {
                        const cacheMatch: [string, string] = outputKeys.find(
                            (set) => set[0] === cachedKeys[0] && set[1] === cachedKeys[1]
                        );

                        if (cacheMatch.length === 2) {
                            if (update && output?.videos) {
                                const mp4Idx: string = output.videos.findIndex(
                                    (x) => x.format === 'mp4'
                                );
                                this.loadInventory('videos', mp4Idx);
                            } else {
                                this.loadInventory(cacheMatch[0], cacheMatch[1]);
                            }
                        } else {
                            this.onInventorySelected(outputKeys[0][0], outputKeys[0][1]);
                        }
                    })
                    .catch(() => {
                        this.onInventorySelected(outputKeys[0][0], outputKeys[0][1]);
                    });
            }
        });
    };

    private onInventorySelected = (typeKey: string, mediaKey: string): void => {
        void StorageService.setItem(Previewer.TAB_CACHE_KEY, [typeKey, mediaKey]);
        this.loadInventory(typeKey, mediaKey);
    };

    private loadInventory = (typeKey: string, mediaKey: string): void => {
        const {
            experiencesList: {
                selected: { output }
            }
        } = this.props;

        if (output) {
            const media: string | any = output[typeKey][mediaKey];
            let tmpImage: HTMLImageElement;

            if (typeof media === 'string') {
                tmpImage = new Image();

                tmpImage.addEventListener('load', () => {
                    this.setState({
                        activeMedia: { mediaKey, typeKey, type: 'image', src: media },
                        mediaWidth: tmpImage.width,
                        mediaHeight: tmpImage.height
                    });
                });
                tmpImage.src = media;
            }

            if (typeof media === 'object' && typeKey === 'videos') {
                this.setState({
                    activeMedia: { mediaKey, typeKey, type: 'video', src: media.url },
                    mediaWidth: parseInt(media.width, 10),
                    mediaHeight: parseInt(media.height, 10)
                });
            }

            if (typeof media === 'object' && typeKey === 'images') {
                this.setState({
                    activeMedia: { mediaKey, typeKey, type: 'image', src: media.url },
                    mediaWidth: parseInt(media.width, 10),
                    mediaHeight: parseInt(media.height, 10)
                });
            }

            if (typeof media === 'object' && typeKey === 'audio') {
                this.setState({
                    activeMedia: { mediaKey, typeKey, type: 'audio', src: media.url },
                    mediaWidth: parseInt(media.width, 10),
                    mediaHeight: parseInt(media.height, 10)
                });
            }
        }
    };

    private closePreviewer = (): void => {
        this.props.togglePreviewer(false);
        this.props.selectExperience({});
    };

    private confirmDelete = (): void => {
        const {
            experiencesList: {
                selected: { id }
            }
        } = this.props;

        const c = confirm(PREVIEWER_COPY.deleteConfirmation.replace('[expId]', id));
        if (c) {
            this.props.deleteExperience(id);
        }
    };

    private confirmBlock = (): void => {
        const {
            experiencesList: {
                selected: { id }
            }
        } = this.props;
        const c = confirm(PREVIEWER_COPY.blockConfirmation);
        if (c) {
            this.props.blockExperience(id);
        }
    };

    public render = (): JSX.Element => {
        const { outputKeys, activeMedia, sectionWidth } = this.state;

        const {
            experiencesList: {
                selected,
                selected: {
                    inventory,
                    notes,
                    error,
                    id: experienceId,
                    date_created: createdOn,
                    moderation_status: moderationStatus,
                    story_id: storyId,
                    composition_id: compId
                }
            }
        } = this.props;

        const activeExists: boolean = Object.keys(selected).length > 1;

        let previewerContent: JSX.Element;

        if (activeExists) {
            previewerContent = (
                <div
                    ref={this.previewerRef}
                    className='previewer'>
                    <OutputSelector
                        key={activeMedia.mediaKey}
                        activeOutputKeys={[activeMedia.typeKey, activeMedia.mediaKey]}
                        outputKeys={outputKeys}
                        onSelectMedia={this.onInventorySelected}
                        selected={selected}
                    />

                    <PreviewerFrame
                        rendering={false}
                        media={activeMedia}
                        error={error}
                    />

                    <ExperienceMetaFields
                        created={createdOn}
                        experienceId={experienceId}
                        moderationStatus={moderationStatus}
                        compId={compId}
                        storyId={storyId}
                        notes={notes}
                        error={error}
                    />

                    <ExperienceVariables
                        inventory={inventory}
                        containerWidth={sectionWidth}
                    />
                </div>
            );
        } else {
            previewerContent = (
                <div className='previewer-empty-state'>
                    <FontAwesomeIcon
                        icon={faPhotoVideo}
                        size='8x'
                    />
                    <div>No Experience Selected</div>
                </div>
            );
        }

        const btnToggleMenu = (
            <Button
                size='small'
                disabled={!activeExists}
                style='subtle'>
                <FontAwesomeIcon icon={faBars} />
            </Button>
        );

        return (
            <Section
                className='preview-section'
                title={PREVIEWER_COPY.sectionTitle}
                buttons={[
                    <ButtonMenu
                        key='exp-options'
                        position='left'
                        items={this.dropdownMenuItems}
                        button={btnToggleMenu}
                    />,
                    <Button
                        key='preview-settings'
                        style='subtle'
                        size='small'
                        buttonRef={this.playbackSettingsToggleRef}
                        tooltip={PREVIEWER_COPY.tooltips.settings}
                        onClick={() =>
                            this.setState({
                                showPlaybackSettings: !this.state.showPlaybackSettings
                            })
                        }>
                        <FontAwesomeIcon icon={faCog} />
                    </Button>,
                    <Button
                        key='experiences-shortcuts'
                        style='subtle'
                        size='small'
                        tooltip={PREVIEWER_COPY.tooltips.shortcuts}
                        buttonRef={this.shortcutsToggleRef}
                        onClick={() =>
                            this.setState({ showShortcutsMenu: !this.state.showShortcutsMenu })
                        }>
                        <FontAwesomeIcon icon={faKeyboard} />
                    </Button>,
                    <Button
                        key='close'
                        style='subtle'
                        size='small'
                        tooltip={PREVIEWER_COPY.tooltips.close}
                        onClick={this.closePreviewer}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                ]}>
                {previewerContent}

                <ImposiumDropdown
                    position='bottomright'
                    show={this.state.showPlaybackSettings}
                    toggleRef={this.playbackSettingsToggleRef}
                    onOutsideClick={() => this.setState({ showPlaybackSettings: false })}>
                    <PlaybackSettingsMenu />
                </ImposiumDropdown>

                <ImposiumDropdown
                    position='bottomright'
                    show={this.state.showShortcutsMenu}
                    toggleRef={this.shortcutsToggleRef}
                    onOutsideClick={() => this.setState({ showShortcutsMenu: false })}>
                    <ShortcutMenu shortcutCopyMap={PREVIEWER_COPY.shortcuts} />
                </ImposiumDropdown>

                <ReactResizeDetector
                    handleWidth
                    onResize={(w) => this.copySectionWidth(w)}
                />
            </Section>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            selectExperience,
            updateModerationStatus,
            togglePreviewer,
            deleteExperience,
            blockExperience
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return { panes: state.panes, experiencesList: state.experiencesList };
};

export default connect(mapStateToProps, mapDispatchToProps)(Previewer);
