import { api } from '../../constants/app';
import { updateTotalRows } from './batches';
import { parseEGCOptions, parseInventory } from '../../util/story-parsing-utils';
import { ToastService } from '@imposium-hub/components';

// This value is currently hard coded on our servers so for now it can live as a constant here
export const BATCH_ITEMS_PER_PAGE: number = 50;

const EXPERIENCE_ID_COL: any = {
    name: 'experience_id',
    type: 'EGC',
    from_import: 0,
    detail: 'id'
};

const actions: any = {
    TOGGlE_LOADING: 'activeBatch/TOGGLE_LOADING', // controls table loader
    TOGGLE_UPDATING: 'activeBatch/TOGGLE_UPDATING', // controls section loader
    SET_BATCH: 'activeBatch/SET_BATCH',
    ADD_COLUMN: 'activeBatch/ADD_COLUMN',
    REPLACE_COLUMNS: 'activeBatch/REPLACE_COLUMNS',
    UPDATE_ASSOCIATION: 'activeBatch/UPDATE_ASSOCIATION',
    UPDATE_NAME: 'activeBatch/UPDATE_NAME',
    INSERT_ROW: 'activeBatch/INSERT_ROW',
    EDIT_CELL_VALUE: 'activeBatch/EDIT_CELL_VALUE',
    REPLACE_ASSOCIATION_OPTIONS: 'activeBatch/REPLACE_ASSOCIATION_OPTIONS',
    REMOVE_COLUMN: 'activeBatch/REMOVE_COLUMN',
    SET_PAGE: 'activeBatch/SET_PAGE'
};

export const setPage = (page: number) => ({ type: actions.SET_PAGE, page });
export const replaceColumns = (columns: any[]): any => ({ type: actions.REPLACE_COLUMNS, columns });

export const addColumn = (): any => {
    return (dispatch) => {
        dispatch({ type: actions.ADD_COLUMN });
        dispatch(persistColumns());
    };
};

export const addPredefinedColumn = (columnData: any) => {
    return (dispatch) => {
        dispatch({ type: actions.ADD_COLUMN, columnData });
        dispatch(persistColumns());
    };
};

export const removeColumn = (colIndex: number) => {
    return (dispatch) => {
        dispatch({ type: actions.REMOVE_COLUMN, colIndex });
        dispatch(persistColumns());
    };
};

export const updateColumnName = (colIndex: number, newName: string) => {
    return (dispatch) => {
        dispatch({ type: actions.UPDATE_NAME, colIndex, newName });
        dispatch(persistColumns());
    };
};

export const updateAssociation = (colIndex: number, newType: string, newAssociation: string) => {
    return (dispatch) => {
        dispatch({ type: actions.UPDATE_ASSOCIATION, colIndex, newType, newAssociation });
        dispatch(persistColumns());
    };
};

export const getBatch = (): any => {
    return (dispatch, getStore) => {
        const {
            story: { data: storyData },
            batchesList: { selected, multiSelected },
            activeTab,
            experiencesList: { filters }
        } = getStore();

        const filtersCopy: any = { ...filters };

        for (const key in filtersCopy) {
            if (filtersCopy.hasOwnProperty(key)) {
                if (!filtersCopy[key]) {
                    delete filtersCopy[key];
                }

                if (Array.isArray(filtersCopy[key]) && filtersCopy[key].length > 0) {
                    filtersCopy[key] = filtersCopy[key].join(',');
                }
            }
        }

        return new Promise<void>((resolve, reject) => {
            dispatch({ type: actions.TOGGlE_LOADING, toggle: true });
            let selectedId = selected.id;

            if (multiSelected.length > 1) {
                const idx = activeTab - 1;
                selectedId = multiSelected[idx].id;
            }

            api.getBatch(selectedId, filtersCopy)
                .then((batch: any) => {
                    dispatch({ type: actions.SET_BATCH, batch });

                    const doResolve = () => {
                        const inventory: any = parseInventory(storyData);
                        const EGC: any[] = parseEGCOptions(storyData);
                        const UGC: any[] = Object.keys(inventory).map((key: string) => ({
                            label: inventory[key].name,
                            value: inventory[key].id
                        }));
                        UGC.push({
                            label: 'composition_id',
                            value: 'composition_id'
                        });
                        UGC.push({
                            label: 'composition_tag',
                            value: 'composition_tag'
                        });

                        dispatch({
                            type: actions.REPLACE_ASSOCIATION_OPTIONS,
                            associationOptions: { UGC, EGC }
                        });
                        dispatch({ type: actions.TOGGlE_LOADING, toggle: false });
                        resolve();
                    };

                    if (batch.columns.length === 0) {
                        // force experience id column in if the batch has 0 columns
                        dispatch(addPredefinedColumn(EXPERIENCE_ID_COL));
                        doResolve();
                    } else {
                        doResolve();
                    }
                })
                .catch((e: Error) => {
                    console.error('Failed to load batch:', e);
                    dispatch({ type: actions.TOGGlE_LOADING, toggle: false });
                    reject(e);
                });
        });
    };
};

export const persistColumns = (): any => {
    return (dispatch, getStore) => {
        const {
            activeBatch: {
                data: { id, columns }
            }
        } = getStore();

        dispatch({ type: actions.TOGGLE_UPDATING, toggle: true });
        return new Promise((resolve, reject) => {
            api.updateBatchColumns(id, columns)
                .catch((e) => dispatch(onSyncError(e)))
                .finally(() => {
                    dispatch({ type: actions.TOGGLE_UPDATING, toggle: false });
                });
        });
    };
};

export const insertNewRow = (rowIndex: number): any => {
    return (dispatch, getStore) => {
        const {
            activeBatch: {
                data: { id, total_rows: totalRows }
            }
        } = getStore();
        const rowIndexAdjusted: number = rowIndex < 0 ? 0 : rowIndex;
        const freshTotal: number = totalRows + 1;

        dispatch(updateTotalRows(id, freshTotal));
        dispatch({ type: actions.INSERT_ROW, rowIndex: rowIndexAdjusted, freshTotal });
        dispatch({ type: actions.TOGGLE_UPDATING, toggle: true });
        api.insertNewRow(id, rowIndexAdjusted)
            .catch((e) => dispatch(onSyncError(e)))
            .finally(() => {
                dispatch({ type: actions.TOGGLE_UPDATING, toggle: false });
            });
    };
};

export const updateCellValue = (nextCellValue: string, colIndex: number, rowIndex: number): any => {
    return (dispatch, getStore) => {
        const {
            activeBatch: {
                data: { id }
            }
        } = getStore();

        dispatch({ type: actions.EDIT_CELL_VALUE, colIndex, rowIndex, value: nextCellValue });
        dispatch({ type: actions.TOGGLE_UPDATING, toggle: true });
        api.updateCellValue(id, colIndex, rowIndex, nextCellValue)
            .catch((e) => dispatch(onSyncError(e)))
            .finally(() => {
                dispatch({ type: actions.TOGGLE_UPDATING, toggle: false });
            });
    };
};

const onSyncError = (e: Error): any => {
    return (dispatch) => {
        console.error('Failed to update data set:', e);
        ToastService.emit('error', 'Failed to update data set');
        dispatch(getBatch()); // force the state into sync with the DB if we are seeing errors
    };
};

export default actions;
