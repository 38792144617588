import * as React from 'react';
import { Button } from '@imposium-hub/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { renderBatch, cancelBatchRenderJob } from '../../redux/actions/batch-jobs';
import { BATCHES_COPY } from '../../constants/copy';

interface IBatchesTableRenderCellProps {
    cell: any;
    renders: any;
    renderBatch: (batchId: string) => any;
    cancelBatchRenderJob: (batchId: string) => any;
}

class BatchesTableRenderCell extends React.PureComponent<IBatchesTableRenderCellProps> {
    constructor(p: IBatchesTableRenderCellProps) {
        super(p);
    }

    public handleRenderBatch(batchId: string, batchStatus) {
        if (batchStatus === BATCHES_COPY.progress.rendered) {
            const c = confirm(BATCHES_COPY.renderAlert);
            if (c) {
                this.props.renderBatch(batchId);
            }
        } else {
            this.props.renderBatch(batchId);
        }
    }

    public render = (): JSX.Element => {
        const {
            cell: {
                row: { original }
            },
            renders
        } = this.props;
        const selectedBatchRendering: boolean = renders.hasOwnProperty(original.id);

        return (
            <Button
                key='render'
                style='subtle'
                size='small'
                disabled={original.total_rows === null || original.total_rows === 0}
                tooltip={
                    !selectedBatchRendering
                        ? BATCHES_COPY.tooltips.tableRender
                        : BATCHES_COPY.tooltips.tableCancelRender
                }
                onClick={
                    !selectedBatchRendering
                        ? () => this.handleRenderBatch(original.id, original.status)
                        : () => this.props.cancelBatchRenderJob(original.id)
                }>
                {!selectedBatchRendering ? (
                    <FontAwesomeIcon icon={faPlay} />
                ) : (
                    <FontAwesomeIcon icon={faTimesCircle} />
                )}
            </Button>
        );
    };
}

const mapStateToProps = (state) => ({ renders: state.batchJobs.renders });

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ renderBatch, cancelBatchRenderJob }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesTableRenderCell);
