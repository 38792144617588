import * as React from 'react';
import { formatDateDefault } from '@imposium-hub/components';

interface IBatchesTableDateCellProps {
    cell: any; // React table 'cell' wrapper
}

const BatchesTableDateCell: React.FC<IBatchesTableDateCellProps> = (
    p: IBatchesTableDateCellProps
) => {
    const { row } = p.cell;
    return <div>{formatDateDefault(row.values.date_created)}</div>;
};

export default BatchesTableDateCell;
