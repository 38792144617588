import * as React from 'react';
import { formatDateDefault } from '@imposium-hub/components';

interface IExperiencesTableDateCell {
    cell: any; // React table 'cell' wrapper
}

const ExperiencesTableDateCell: React.FC<IExperiencesTableDateCell> = (
    p: IExperiencesTableDateCell
) => {
    const { row } = p.cell;
    return <div>{formatDateDefault(row.values.date_created)}</div>;
};

export default ExperiencesTableDateCell;
