import * as React from 'react';
import { Button } from '@imposium-hub/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteBatch } from '../../redux/actions/batches';
import { BATCHES_COPY } from '../../constants/copy';

interface IBatchesTableCloneCellProps {
    cell: any;
    deleteBatch: (batchId: string) => any;
}

class BatchesTableDeleteCell extends React.PureComponent<IBatchesTableCloneCellProps> {
    constructor(p: IBatchesTableCloneCellProps) {
        super(p);
    }

    private doDelete = (e): void => {
        e.stopPropagation();

        if (
            window.confirm(
                `Are you sure you want to delete batch: ${this.props.cell.row.original.name}`
            )
        ) {
            this.props.deleteBatch(this.props.cell.row.original.id);
        }
    };

    public render = (): JSX.Element => {
        return (
            <Button
                key='delete'
                style='subtle'
                size='small'
                tooltip={BATCHES_COPY.tooltips.tableDelete}
                onClick={this.doDelete}>
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
        );
    };
}

const mapStateToProps = (state) => ({ columnMasks: state.columnMasks });

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ deleteBatch }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesTableDeleteCell);
