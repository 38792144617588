export const parseInventory = (story: any): any => {
    const { acts } = story;
    const actsKeys: string[] = Object.keys(acts);
    let inventory: any = {};

    actsKeys.forEach((actKey: string) => {
        inventory = { ...inventory, ...acts[actKey].inventory };
    });

    return inventory;
};

/*
    Right now this pulls out the first video scene (is there a need to account for many acts?)
 */
export const parseEGCOptions = (story: any): any[] => {
    const { acts } = story;
    const EGCOptions: any[] = [];
    const duplicateFormatKeys: string[] = [];
    const actsKeys: string[] = Object.keys(acts);
    const firstAct: any = acts[actsKeys[0]];

    if (typeof firstAct === 'undefined') {
        return [];
    }

    const sceneKeys = Object.keys(firstAct.scenes);
    const firstVideoScene: any = firstAct.scenes[sceneKeys[0]];

    if (typeof firstVideoScene === 'undefined') {
        return [];
    }

    EGCOptions.push(
        {
            label: 'Experience Id',
            value: 'id'
        },
        {
            label: 'Story Id',
            value: 'story_id'
        }
    );

    firstVideoScene.sceneData.encodingSettings.forEach((encodingSetting: any) => {
        if (encodingSetting.extension && encodingSetting.height) {
            const formatName: string = encodingSetting.name;
            const formatKey: string = `${encodingSetting.extension}_${encodingSetting.height}`;

            if (typeof duplicateFormatKeys[formatKey] === 'undefined') {
                duplicateFormatKeys[formatKey] = 0;
            }
            duplicateFormatKeys[formatKey]++;

            EGCOptions.push({
                label: formatName,
                value:
                    duplicateFormatKeys[formatKey] === 1
                        ? `video_url_${formatKey}`
                        : `video_url_${formatKey}_${duplicateFormatKeys[formatKey].toString()}`
            });
        }
    });

    if (firstVideoScene.exportAsPlaylist) {
        EGCOptions.push({
            label: 'M3U8',
            value: 'video_url_m3u8'
        });
    }

    if (firstVideoScene.sceneData.savePosterFrame) {
        EGCOptions.push({
            label: 'Poster Frame',
            value: 'poster'
        });
    }

    if (firstVideoScene.sceneData.saveSnapshotFrame) {
        EGCOptions.push({
            label: 'Snapshot Frame',
            value: 'snapshot'
        });
    }

    if (firstVideoScene.sceneData.saveSocialFrame) {
        EGCOptions.push({
            label: 'Social Frame',
            value: 'social'
        });
    }

    return EGCOptions;
};
