import { api } from '../../constants/app';
import { inventory2ColumnMasks } from './column-masks';
import { selectBatch } from './batches';
import { resetTags } from './tags';

const actions: any = {
    TOGGlE_LOADING: 'story/TOGGLE_LOADING',
    SET_STORY: 'story/SET_STORY'
};

export const getStory = (storyId: string): any => {
    return (dispatch, getStore) => {
        const {
            story: { data }
        } = getStore();

        return new Promise<void>((resolve, reject) => {
            dispatch({ type: actions.TOGGlE_LOADING, toggle: true });

            api.getStory(storyId)
                .then((story: any) => {
                    dispatch({ type: actions.SET_STORY, story });
                    dispatch(inventory2ColumnMasks());

                    if (Object.keys(data).length > 0) {
                        dispatch(selectBatch({}));
                        dispatch(resetTags());
                    }

                    dispatch({ type: actions.TOGGlE_LOADING, toggle: false });
                    resolve();
                })
                .catch((e: Error) => {
                    console.error('Failed to load story:', e);
                    dispatch({ type: actions.TOGGlE_LOADING, toggle: false });
                    reject(e);
                });
        });
    };
};

export default actions;
