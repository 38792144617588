import * as React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    VideoPreview,
    ImagePreview,
    getMediaPreviewStyle,
    AudioPreview
} from '@imposium-hub/components';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faMusic } from '@fortawesome/free-solid-svg-icons/faMusic';
import { getVideoInfo } from '../previewer/VideoPreviewer';

interface IExperiencesTableVariableCellProps {
    inventoryKey: string;
    tableState: any; // React table 'cell' wrapper
    playbackSettings: any;
    story: any;
}

interface IExperiencesTableVariableCellState {
    showMedia: boolean;
}

export class ExperiencesTableVariableCell extends React.PureComponent<
    IExperiencesTableVariableCellProps,
    IExperiencesTableVariableCellState
> {
    private videoPlayerRef: any = React.createRef<HTMLVideoElement>();

    private iconRef: any = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            showMedia: false
        };
    }

    public componentDidMount() {
        this.setPlaybackRate();
    }

    public componentDidUpdate(prevProps) {
        this.setPlaybackRate();
    }

    private setPlaybackRate() {
        const { playbackSettings } = this.props;
        const player = this.videoPlayerRef.current;
        if (player) {
            player.playbackRate = playbackSettings.rate;
        }
    }

    public render() {
        const { inventoryKey, playbackSettings, story } = this.props;
        const inventory = this.props.tableState.cell.row.original.inventory || undefined;
        const inventoryItem: any = inventory[inventoryKey];
        const { showMedia } = this.state;

        const handleMouseEnter = (e: any) => {
            e.persist();
            this.setState({
                showMedia: true
            });
        };

        const closeMedia = () => {
            this.setState({
                showMedia: false
            });
        };

        if (typeof inventoryItem !== 'object') {
            return null;
        }

        if (inventoryItem.type === 'video') {
            const { width, height, url } = getVideoInfo(
                playbackSettings,
                story,
                inventoryKey,
                inventoryItem
            );

            return (
                <span
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={closeMedia}
                    ref={this.iconRef}>
                    <FontAwesomeIcon icon={faVideo} />
                    {showMedia && (
                        <VideoPreview
                            playbackSettings={playbackSettings}
                            style={{ ...getMediaPreviewStyle(width, height, this.iconRef) }}
                            url={url}
                            ref={this.videoPlayerRef}
                            onRequestClose={closeMedia}
                        />
                    )}
                </span>
            );
        }

        if (inventoryItem.type === 'image' && inventoryItem.url) {
            return (
                <span
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={closeMedia}
                    ref={this.iconRef}>
                    <FontAwesomeIcon icon={faImage} />
                    {showMedia && (
                        <ImagePreview
                            url={inventoryItem.url}
                            style={{
                                ...getMediaPreviewStyle(
                                    inventoryItem.width,
                                    inventoryItem.height,
                                    this.iconRef
                                )
                            }}
                            onRequestClose={closeMedia}
                        />
                    )}
                </span>
            );
        }

        if (inventoryItem.type === 'audio' && inventoryItem.url) {
            return (
                <span
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={closeMedia}
                    ref={this.iconRef}>
                    <FontAwesomeIcon icon={faMusic} />
                    {showMedia && (
                        <AudioPreview
                            playbackSettings={playbackSettings}
                            style={{
                                ...getMediaPreviewStyle(
                                    inventoryItem.width,
                                    inventoryItem.height,
                                    this.iconRef
                                )
                            }}
                            onRequestClose={closeMedia}
                            url={inventoryItem.url}
                        />
                    )}
                </span>
            );
        }

        if (inventoryItem.type === 'text' || inventoryItem.type === 'enum') {
            return inventoryItem.value;
        }

        if (inventoryItem.type === 'number' || inventoryItem.type === 'boolean') {
            return `${inventoryItem.value}`;
        }

        return null;
    }
}

const mapStateToProps = (state): any => {
    return {
        playbackSettings: state.playbackSettings,
        story: state.story.data
    };
};

const ExperiencesTableVariableCellMemoized = React.memo(ExperiencesTableVariableCell);
export default connect(mapStateToProps, {})(ExperiencesTableVariableCellMemoized);
