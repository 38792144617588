import * as React from 'react';
import { Tag } from '@imposium-hub/components';

interface IBatchesTableTotalRowsCellProps {
    cell: any; // React table 'cell' wrapper
}

const BatchesTableTotalRowsCell: React.FC<IBatchesTableTotalRowsCellProps> = (
    p: IBatchesTableTotalRowsCellProps
) => (
    <Tag
        colorize
        copy={p.cell.row.values.total_rows.toString()}
    />
);

export default BatchesTableTotalRowsCell;
