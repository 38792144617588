import * as React from 'react';
import { HRule, TextField } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createFreshBatch } from '../../redux/actions/batches';
import { BATCHES_COPY } from '../../constants/copy';

interface IExperienceMetaFieldsProps {
    close: () => void;
    createFreshBatch: (batchName: string) => any;
}

class ExperienceMetaFields extends React.PureComponent<IExperienceMetaFieldsProps> {
    constructor(p: IExperienceMetaFieldsProps) {
        super(p);
    }

    private doCreate = (v) => {
        this.props.createFreshBatch(v);
        this.props.close();
    };

    public render = (): JSX.Element => {
        return (
            <div className='new-batch-menu'>
                <p>{BATCHES_COPY.newMenu.cta}</p>
                <HRule style='subtle' />
                <TextField
                    focusOnMount
                    submittable
                    submittableType='add'
                    width='300px'
                    label={BATCHES_COPY.newMenu.nameLabel}
                    doSubmit={this.doCreate}
                />
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ createFreshBatch }, dispatch);
};

export default connect(null, mapDispatchToProps)(ExperienceMetaFields);
