/**
 * [initPendo description]
 * 	This initalizes the Pendo Help system in the application
 * 		The Pendo script should be added to the DOM in `index.html`
 * 	This function takes the user object and sets some initial information on Pendo for tracking
 * 		For full documentation of Pendo object please see Pendo Docs
 *
 * 		To prevent application breaking things, we wrap both groups in a try/catch
 * 		This allows our Application to work regardless of the status of Pendo
 * 		*************************************************************
 * 		 We DO NOT WANT TO BREAK OUR APP if a 3rd party script fails
 *    	*************************************************************
 *
 * @param  {object} userInfo User object from crm/v1/user
 *                           Each application's `user/` endpoint returns slightly different data
 *                           The `visitor` object will need to be adjusted based on the data returned
 *                           	We should always have access to:
 *                           	id
 *                           	email
 *                           	full_name
 *
 * @return {undefined}          Nothing
 */

declare const pendo;

export const initPendo = (userInfo) => {
    if (/dev/gi.test(import.meta.env.VITE_ENV)) {
        console.info(
            '%cPendo%c is %cdisabled%c for local testing',
            'color: #e247e5; font-weight:bold;',
            'color:#fff; font-weight:normal;',
            'color:red; font-weight:bold;',
            'color:#fff; font-weight:normal;'
        );
        return;
    }

    try {
        const visitor = {
            id: userInfo.sub,
            email: userInfo['https://imposium.com/email']
        };

        /* eslint-disable no-undef */
        pendo.initialize({
            visitor: {
                ...visitor,

                environment: import.meta.env.VITE_ENV,
                product: 'Imposium Experience Manager',
                language: navigator.language
            }
        });
        /* eslint-enable no-undef */
    } catch (err) {
        console.groupCollapsed('Pendo Init Problem');
        console.error('Problem Starting up Pendo');
        console.error(err);
        console.groupEnd();
    }
};
