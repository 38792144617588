import * as React from 'react';
import ReactTags from 'react-tag-autocomplete';
import ReactResizeDetector from 'react-resize-detector';
import { Tag, HRule } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addTag, selectTag, removeTag } from '../../redux/actions/tags';
import { EXPERIENCES_COPY } from '../../constants/copy';

interface IExperiencesFilterControlsProps {
    tags: any[];
    columnMasks: any[];
    addTag: (tag: any) => any;
    selectTag: (tag: any) => any;
    removeTag: (tagIndex: number) => any;
    onHeightChange: (h: number) => void;
}

interface IExperiencesFilterControlsState {
    tmpSuggestions: any[];
}

class ExperiencesFilterControls extends React.Component<
    IExperiencesFilterControlsProps,
    IExperiencesFilterControlsState
> {
    private static STATIC_SUGGESTIONS: any[] = [
        {
            id: 'moderation:approved',
            name: EXPERIENCES_COPY.tagNames.moderationApproved
        },
        {
            id: 'moderation:pending',
            name: EXPERIENCES_COPY.tagNames.moderationPending
        },
        {
            id: 'moderation:used',
            name: EXPERIENCES_COPY.tagNames.moderationUsed
        },
        {
            id: 'moderation:vip',
            name: EXPERIENCES_COPY.tagNames.moderationVIP
        },
        {
            id: 'moderation:rejected',
            name: EXPERIENCES_COPY.tagNames.moderationRejected
        },
        {
            id: 'moderation:failed',
            name: EXPERIENCES_COPY.tagNames.moderationFailed
        }
    ];

    private static readonly EXP_ID_SUGGESTION_PREFIXES: any = {
        id: 'id:',
        name: EXPERIENCES_COPY.tagNames.experienceId
    };

    private static readonly BATCH_ID_SUGGESTION_PREFIXES: any = {
        id: 'batch_id:',
        name: EXPERIENCES_COPY.tagNames.batchId
    };

    private static readonly INVENTORY_SUGGESTION_PREFIXES: any = {
        id: 'inventory_item:',
        name: 'Variable - '
    };

    private static readonly CREATIVE_ID_SUGGESTION_PREFIXES: any = {
        id: 'inventory_item:creative_id',
        name: EXPERIENCES_COPY.tagNames.creativeId
    };

    private static readonly VERSION_LIST_ID_SUGGESTION_PREFIXES: any = {
        id: 'inventory_item:version_list_id',
        name: EXPERIENCES_COPY.tagNames.versionListId
    };

    private static readonly CREATIVE_LIBRARY_ID_SUGGESTION_PREFIXES: any = {
        id: 'inventory_item:creative_library_id',
        name: EXPERIENCES_COPY.tagNames.creativeLibraryId
    };

    constructor(props: IExperiencesFilterControlsProps) {
        super(props);

        this.state = {
            tmpSuggestions: []
        };
    }

    private validateInput = (tag: any): boolean =>
        typeof this.props.tags.find((t: any) => t.id === tag.id) === 'undefined';

    private handleInputChange = (input: string): void => {
        const { columnMasks } = this.props;
        const tmpSuggestions: any[] = [];

        input = input.trim(); // trim off whitespace

        tmpSuggestions.push(
            {
                id: `${ExperiencesFilterControls.EXP_ID_SUGGESTION_PREFIXES.id}${input}`,
                name: `${ExperiencesFilterControls.EXP_ID_SUGGESTION_PREFIXES.name}${input}`
            },
            {
                id: `${ExperiencesFilterControls.BATCH_ID_SUGGESTION_PREFIXES.id}${input}`,
                name: `${ExperiencesFilterControls.BATCH_ID_SUGGESTION_PREFIXES.name}${input}`
            }
        );

        if (columnMasks.length > 0) {
            columnMasks.forEach((mask: any) => {
                tmpSuggestions.push(
                    {
                        id: `${ExperiencesFilterControls.INVENTORY_SUGGESTION_PREFIXES.id}${mask.id} | ${input}`,
                        name: `${ExperiencesFilterControls.INVENTORY_SUGGESTION_PREFIXES.name}${mask.name}: ${input}`
                    },
                    {
                        id: `${ExperiencesFilterControls.CREATIVE_ID_SUGGESTION_PREFIXES.id} | ${input}`,
                        name: `${ExperiencesFilterControls.CREATIVE_ID_SUGGESTION_PREFIXES.name} ${input}`
                    },
                    {
                        id: `${ExperiencesFilterControls.VERSION_LIST_ID_SUGGESTION_PREFIXES.id} | ${input}`,
                        name: `${ExperiencesFilterControls.VERSION_LIST_ID_SUGGESTION_PREFIXES.name} ${input}`
                    },
                    {
                        id: `${ExperiencesFilterControls.CREATIVE_LIBRARY_ID_SUGGESTION_PREFIXES.id} | ${input}`,
                        name: `${ExperiencesFilterControls.CREATIVE_LIBRARY_ID_SUGGESTION_PREFIXES.name} ${input}`
                    }
                );
            });
        }

        if (tmpSuggestions.length > 0) {
            this.setState({ tmpSuggestions });
        }
    };

    private handleTagClick = (active, s, tagIndex) => (e) => {
        e.persist();
        // Prevents text highlight when shift clicking
        document.getSelection().removeAllRanges();
        if (e.shiftKey) {
            return active ? this.props.removeTag(tagIndex) : this.props.addTag(s);
        }
        return this.props.selectTag(s);
    };

    public render = (): JSX.Element => {
        const { tmpSuggestions } = this.state;
        const moderationFilterButtons: JSX.Element[] =
            ExperiencesFilterControls.STATIC_SUGGESTIONS.map((s: any) => {
                const tagIndex: number = this.props.tags.findIndex((t: any) => t.id === s.id);
                const active: boolean = tagIndex > -1;

                return (
                    <button
                        key={s.id}
                        className={`moderation-filter-button ${active ? 'active' : ''}`}
                        onClick={this.handleTagClick(active, s, tagIndex)}>
                        {s.id.split(':')[1]}
                    </button>
                );
            });

        return (
            <div className='experiences-filter-controls'>
                <ReactResizeDetector
                    handleHeight
                    onResize={(w, h) => {
                        this.props.onHeightChange(Math.floor(h));
                    }}
                />

                <div className='moderation-tab-group'>{moderationFilterButtons}</div>

                <ReactTags
                    // allowNew
                    autoresize={false}
                    placeholderText={EXPERIENCES_COPY.filterBarPlaceholder}
                    tags={this.props.tags}
                    suggestions={[
                        ...tmpSuggestions,
                        ...ExperiencesFilterControls.STATIC_SUGGESTIONS
                    ]}
                    onInput={this.handleInputChange}
                    onValidate={this.validateInput}
                    onAddition={this.props.addTag}
                    onDelete={this.props.removeTag}
                    tagComponent={(p) => (
                        <Tag
                            copy={p.tag.name}
                            removeHandler={p.onDelete}
                        />
                    )}
                    maxSuggestionsLength={
                        tmpSuggestions.length + ExperiencesFilterControls.STATIC_SUGGESTIONS.length
                    }
                />

                <HRule style='subtle' />
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ addTag, selectTag, removeTag }, dispatch);
};

const mapStateToProps = (state): any => {
    return { tags: state.tags, columnMasks: state.columnMasks };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencesFilterControls);
