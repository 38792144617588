import * as React from 'react';
import { Button, Tag } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { BATCHES_COPY } from '../../constants/copy';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { setErrorModal } from '../../redux/actions/batches';

interface IBatchesTableProgressCellProps {
    cell: any; // React table 'cell' wrapper
    batchJobs: any;
    batchesList: any;
    setErrorModal: (e: boolean, i: any) => any;
}

class BatchesTableProgressCell extends React.PureComponent<IBatchesTableProgressCellProps> {
    private static readonly PROGRESS_MAX_VALUE: number = 100;

    constructor(props: IBatchesTableProgressCellProps) {
        super(props);
    }

    public render = (): JSX.Element => {
        const ICON_INFO_CIRCLE = <FontAwesomeIcon icon={faInfoCircle} />;
        const {
            cell: {
                row: { original }
            },
            batchJobs,
            batchesList: { error }
        } = this.props;

        const checkError = () => {
            if (error[original.id]) {
                return (
                    <Button
                        customStyles={{ color: '#ff3d57', marginTop: '5px' }}
                        key={original.id}
                        style='subtle'
                        size='small'
                        onClick={() => this.props.setErrorModal(true, original.id)}>
                        {ICON_INFO_CIRCLE}
                    </Button>
                );
            }
        };

        if (original.status === BATCHES_COPY.progress.notRendered) {
            return (
                <Tag
                    colorize
                    copy={BATCHES_COPY.progress.notRendered}
                />
            );
        }

        if (
            original.status === BATCHES_COPY.progress.rendering &&
            !batchJobs.renders.hasOwnProperty(original.id)
        ) {
            return (
                <Tag
                    colorize
                    copy={BATCHES_COPY.progress.rendering}
                />
            );
        }

        const rowRendered = original.rowsRendered ? (
            <div>{`${BATCHES_COPY.table.rowsRendered} ${original.rowsRendered}`}</div>
        ) : (
            ''
        );
        if (
            original.status === BATCHES_COPY.progress.rendering &&
            batchJobs.renders.hasOwnProperty(original.id)
        ) {
            return (
                <div className='progress-bar'>
                    <progress
                        max={BatchesTableProgressCell.PROGRESS_MAX_VALUE}
                        value={batchJobs.renders[original.id]}
                    />
                    {rowRendered}
                </div>
            );
        }

        if (original.status === BATCHES_COPY.progress.queued) {
            return (
                <Tag
                    colorize
                    copy={BATCHES_COPY.progress.queued}
                />
            );
        }

        if (original.status === BATCHES_COPY.progress.cancelled) {
            return (
                <Tag
                    colorize
                    copy={BATCHES_COPY.progress.cancelled}
                />
            );
        }

        if (original.status === BATCHES_COPY.progress.rendered) {
            const copy = error[original.id]
                ? BATCHES_COPY.progress.failed
                : BATCHES_COPY.progress.rendered;
            return (
                <div>
                    <Tag
                        colorize
                        copy={copy}
                    />
                    {checkError()}
                </div>
            );
        }

        if (original.status === BATCHES_COPY.progress.failed) {
            return (
                <div>
                    <Tag
                        colorize
                        copy={BATCHES_COPY.progress.failed}
                    />
                    {checkError()}
                </div>
            );
        }

        return (
            <Tag
                colorize
                copy={BATCHES_COPY.progress.malformed}
            />
        );
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setErrorModal
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return { batchJobs: state.batchJobs, batchesList: state.batchesList };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesTableProgressCell);
