export type RowSelectHotkey = 'up' | 'down';
export type PageSelectHotkey = 'left' | 'right';

export const getCurrentIndex = (list: any[], key: any, value: any): number =>
    Array.isArray(list) ? list.findIndex((i) => i[key] === value) : 0;

export const calculateNextRow = (
    list: any[],
    currentIndex: number,
    direction: RowSelectHotkey
): number => {
    const increment: boolean = direction === 'down';
    let nextIdx: number = -1;

    if (!Array.isArray(list) || typeof currentIndex === 'undefined') {
        return nextIdx;
    }

    if (increment) {
        nextIdx = currentIndex + 1;
        if (nextIdx >= list.length) {
            return currentIndex;
        }
        if (nextIdx >= 0 && nextIdx < list.length - 1) {
            return nextIdx;
        }
    } else {
        nextIdx = currentIndex - 1;
        if (nextIdx === -1) {
            return currentIndex;
        }
        if (nextIdx >= 0) {
            return nextIdx;
        }
    }

    return nextIdx;
};

export const calculateNextPage = (
    totalPages: number,
    currentPage: number,
    direction: PageSelectHotkey
): number => {
    const increment: boolean = direction === 'right';
    let nextPage: number = -1;

    if (totalPages === 1) {
        return nextPage;
    }

    if (increment) {
        nextPage = currentPage + 1;
        if (nextPage > totalPages) {
            return 0;
        }
        if (nextPage >= 0 && nextPage <= totalPages) {
            return nextPage;
        }
    } else {
        nextPage = currentPage - 1;
        if (nextPage === 0) {
            return totalPages;
        }
        if (nextPage >= 1) {
            return nextPage;
        }
    }

    return nextPage;
};
