import * as React from 'react';
import ReactTags from 'react-tag-autocomplete';
import { Tag, HRule } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addTag, selectTag, removeTag } from '../../redux/actions/tags';
import { EXPERIENCES_COPY, DATA_SET_COPY } from '../../constants/copy';

interface IDataSetFilterControlsProps {
    tags: any[];
    columnMasks: any[];
    addTag: (tag: any) => any;
    selectTag: (tag: any) => any;
    removeTag: (tagIndex: number) => any;
}

interface IDataSetFilterControlsState {
    tmpSuggestions: any[];
}

class DataSetFilterControls extends React.Component<
    IDataSetFilterControlsProps,
    IDataSetFilterControlsState
> {
    private static STATIC_SUGGESTIONS: any[] = [
        {
            id: 'moderation:approved',
            name: EXPERIENCES_COPY.tagNames.moderationApproved
        },
        {
            id: 'moderation:pending',
            name: EXPERIENCES_COPY.tagNames.moderationPending
        },
        {
            id: 'moderation:used',
            name: EXPERIENCES_COPY.tagNames.moderationUsed
        },
        {
            id: 'moderation:vip',
            name: EXPERIENCES_COPY.tagNames.moderationVIP
        },
        {
            id: 'moderation:rejected',
            name: EXPERIENCES_COPY.tagNames.moderationRejected
        },
        {
            id: 'moderation:failed',
            name: EXPERIENCES_COPY.tagNames.moderationFailed
        }
    ];

    private static readonly EXP_ID_SUGGESTION_PREFIXES: any = {
        id: 'id:',
        name: EXPERIENCES_COPY.tagNames.experienceId
    };

    private static readonly INVENTORY_SUGGESTION_PREFIXES: any = {
        id: 'inventory_item:',
        name: 'Variable - '
    };

    constructor(props: IDataSetFilterControlsProps) {
        super(props);

        this.state = {
            tmpSuggestions: []
        };
    }

    private validateInput = (tag: any): boolean =>
        typeof this.props.tags.find((t: any) => t.id === tag.id) === 'undefined';

    private handleInputChange = (input: string): void => {
        const { columnMasks } = this.props;
        const tmpSuggestions: any[] = [];

        input = input.trim(); // trim off whitespace

        tmpSuggestions.push({
            id: `${DataSetFilterControls.EXP_ID_SUGGESTION_PREFIXES.id}${input}`,
            name: `${DataSetFilterControls.EXP_ID_SUGGESTION_PREFIXES.name}${input}`
        });

        if (columnMasks.length > 0) {
            columnMasks.forEach((mask: any) => {
                tmpSuggestions.push({
                    id: `${DataSetFilterControls.INVENTORY_SUGGESTION_PREFIXES.id}${mask.id} | ${input}`,
                    name: `${DataSetFilterControls.INVENTORY_SUGGESTION_PREFIXES.name}${mask.name}: ${input}`
                });
            });
        }

        if (tmpSuggestions.length > 0) {
            this.setState({ tmpSuggestions });
        }
    };

    private handleTagClick = (active, s, tagIndex) => (e) => {
        e.persist();
        // Prevents text highlight when shift clicking
        document.getSelection().removeAllRanges();
        if (e.shiftKey) {
            return active ? this.props.removeTag(tagIndex) : this.props.addTag(s);
        }
        return this.props.selectTag(s);
    };

    public render = (): JSX.Element => {
        const { tmpSuggestions } = this.state;
        const moderationFilterButtons: JSX.Element[] = DataSetFilterControls.STATIC_SUGGESTIONS.map(
            (s: any) => {
                const tagIndex: number = this.props.tags.findIndex((t: any) => t.id === s.id);
                const active: boolean = tagIndex > -1;

                return (
                    <button
                        key={s.id}
                        className={`moderation-filter-button ${active ? 'active' : ''}`}
                        onClick={this.handleTagClick(active, s, tagIndex)}>
                        {s.id.split(':')[1]}
                    </button>
                );
            }
        );

        return (
            <div className='dataset-filter-controls'>
                <div className='moderation-tab-group'>{moderationFilterButtons}</div>

                <ReactTags
                    // allowNew
                    autoresize={false}
                    placeholderText={DATA_SET_COPY.filterBarPlaceholder}
                    tags={this.props.tags}
                    suggestions={[...tmpSuggestions, ...DataSetFilterControls.STATIC_SUGGESTIONS]}
                    onInput={this.handleInputChange}
                    onValidate={this.validateInput}
                    onAddition={this.props.addTag}
                    onDelete={this.props.removeTag}
                    tagComponent={(p) => (
                        <Tag
                            copy={p.tag.name}
                            removeHandler={p.onDelete}
                        />
                    )}
                    maxSuggestionsLength={
                        tmpSuggestions.length + DataSetFilterControls.STATIC_SUGGESTIONS.length
                    }
                />

                <HRule style='subtle' />
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ addTag, selectTag, removeTag }, dispatch);
};

const mapStateToProps = (state): any => {
    return { tags: state.tags, columnMasks: state.columnMasks };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSetFilterControls);
