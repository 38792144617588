import * as React from 'react';
import { Button, formatDateDefault } from '@imposium-hub/components';
import { HRule, TextField, TextAreaField, ButtonGroupField } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateModerationStatus, updateNotes } from '../../redux/actions/experiences';
import { getModerationConfig } from '../ModerationConfig';
import { PREVIEWER_COPY } from '../../constants/copy';
import { api } from '../../constants/app';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { getBatches } from '../../redux/actions/batches';

interface IExperienceMetaFieldsProps {
    story: any;
    created: number;
    experienceId: string;
    moderationStatus: string;
    notes: string;
    compId: string;
    storyId: string;
    error?: string;
    updateModerationStatus: (experienceId: string, status: string) => any;
    updateNotes: (experienceId: string, notes: string) => any;
    getBatches: () => any;
}

class ExperienceMetaFields extends React.PureComponent<IExperienceMetaFieldsProps> {
    constructor(p: IExperienceMetaFieldsProps) {
        super(p);
    }

    private downloadExpLog = (expId) => {
        api.getExperienceLog(expId)
            .then((res: Blob) => {
                saveAs(res, `${expId}.txt`);
            })
            .catch((e) => console.error(e));
    };

    private updateModerationStatus = (id, value) => {
        this.props.updateModerationStatus(id, value).then(() => this.props.getBatches());
    };

    public render = (): JSX.Element => {
        const {
            created,
            experienceId,
            moderationStatus,
            notes,
            compId,
            storyId,
            error,
            story: {
                data: { moderationLockApproval }
            }
        } = this.props;
        const ICON_DOWNLOAD = <FontAwesomeIcon icon={faDownload} />;

        const unlockAppoval =
            (moderationLockApproval && moderationStatus === 'approved') ||
            moderationStatus === 'failed';

        const moderationStatusField = !error ? (
            <ButtonGroupField
                label={PREVIEWER_COPY.metaFields.moderationStatus}
                value={moderationStatus}
                options={getModerationConfig(moderationLockApproval, moderationStatus)}
                onChange={(v) =>
                    !unlockAppoval ? this.updateModerationStatus(experienceId, v) : null
                }
                disabled={unlockAppoval}
            />
        ) : null;
        return (
            <div className='experience-meta-fields'>
                <HRule style='subtle' />

                <TextField
                    readOnly
                    label={PREVIEWER_COPY.metaFields.createdLabel}
                    value={formatDateDefault(created)}
                />

                <TextField
                    showCopy
                    readOnly
                    width='85%'
                    label={PREVIEWER_COPY.metaFields.idLabel}
                    value={experienceId}
                />

                <Button
                    key='btn-expLog'
                    tooltip={PREVIEWER_COPY.metaFields.expJobLogToolTips}
                    customStyles={{
                        position: 'relative',
                        bottom: '2px'
                    }}
                    onClick={() => this.downloadExpLog(experienceId)}>
                    {PREVIEWER_COPY.metaFields.expJobLog} {ICON_DOWNLOAD}
                </Button>

                <TextField
                    showCopy
                    readOnly
                    label={PREVIEWER_COPY.metaFields.compId}
                    value={compId}
                />

                <TextField
                    showCopy
                    readOnly
                    label={PREVIEWER_COPY.metaFields.storyId}
                    value={storyId}
                />

                {moderationStatusField}

                <TextAreaField
                    controlled
                    label={PREVIEWER_COPY.metaFields.notes}
                    value={notes}
                    onSubmit={(v) => this.props.updateNotes(experienceId, v)}
                />
            </div>
        );
    };
}

const mapStateToProps = (state): any => {
    return {
        story: state.story
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateModerationStatus, updateNotes, getBatches }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceMetaFields);
