import * as React from 'react';
import { SelectField } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fitToContainer } from '../../util/helpers';
import { parseInventory } from '../../util/story-parsing-utils';
import { setVersions } from '../../redux/actions/playback-settings';

interface IVideoPreviewerProps {
    inventory: any;
    invId: string;
    maxWidth: number;
    maxHeight: number;
    playbackSettings: any;
    setVersions(ver: any): void;
    story: any;
}

class VideoPreviewer extends React.PureComponent<IVideoPreviewerProps> {
    private readonly videoPlayerRef: any;

    private handleUpdateVersion: any = (e) => this.updateVersion(e);

    constructor(p: IVideoPreviewerProps) {
        super(p);
        this.videoPlayerRef = React.createRef();
    }

    public componentDidMount() {
        this.setPlaybackRate();
    }

    public componentDidUpdate(prevProps, prevState) {
        this.setPlaybackRate();
    }

    private setPlaybackRate() {
        const { playbackSettings } = this.props;
        const player = this.videoPlayerRef.current;
        if (player) {
            player.playbackRate = playbackSettings.rate;
        }
    }

    private updateVersion(e) {
        const { invId, playbackSettings } = this.props;
        const versions = { ...playbackSettings.versions };
        versions[invId] = e;

        this.props.setVersions(versions);
    }

    private renderVersionSelect() {
        const { story, invId, playbackSettings } = this.props;
        const inventoryItems = parseInventory(story);
        const selectedItem = inventoryItems[invId];

        const versions = selectedItem.versions;

        if (versions) {
            if (versions.length > 0) {
                const versionOptions = [];
                const selected = playbackSettings.selectedVersions[invId] || versions[0].id;

                for (const version of versions) {
                    versionOptions.push({
                        value: version.id,
                        label: version.name
                    });
                }

                versionOptions.push({
                    value: 'original',
                    label: 'Original'
                });

                return (
                    <SelectField
                        value={selected}
                        onChange={this.handleUpdateVersion}
                        options={versionOptions}
                    />
                );
            }
        }

        return null;
    }

    public render = (): JSX.Element => {
        const { inventory, maxWidth, maxHeight, story, invId, playbackSettings } = this.props;

        const { width, height, url } = getVideoInfo(playbackSettings, story, invId, inventory);

        const dims = fitToContainer(
            {
                width: maxWidth,
                height: maxHeight
            },
            {
                width,
                height
            },
            {
                scaleMode: 'proportionalInside',
                hAlign: 'left',
                vAlign: 'center'
            }
        );

        const style = {
            width: dims.width,
            height: dims.height
        };

        return (
            <div className='variable-preview-frame'>
                <video
                    style={style}
                    muted
                    autoPlay={playbackSettings.autoPlay}
                    loop={playbackSettings.loop}
                    controls
                    playsInline
                    preload='preload'
                    ref={this.videoPlayerRef}
                    src={url}
                />
                {this.renderVersionSelect()}
            </div>
        );
    };
}

export const getVideoInfo = (playbackSettings, story, invId, inventory) => {
    const selectedVersion = playbackSettings.selectedVersions[invId];

    let url = inventory.url;
    let width = inventory.width;
    let height = inventory.height;

    if (selectedVersion) {
        const versions = inventory.versions;
        if (versions) {
            const outputVersion = versions[selectedVersion];
            if (outputVersion) {
                const inventoryItems = parseInventory(story);
                const item = inventoryItems[invId];
                if (item) {
                    for (const version of item.versions) {
                        if (version.id === selectedVersion) {
                            width = version.width;
                            height = version.height;
                            break;
                        }
                    }
                }
                url = outputVersion.url;
            }
        }
    }

    return {
        width,
        height,
        url
    };
};

const mapStateToProps = (state): any => {
    return { playbackSettings: state.playbackSettings, story: state.story.data };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setVersions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPreviewer);
