import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { insertNewRow, updateCellValue } from '../../redux/actions/active-batch';
import { Button } from '@imposium-hub/components';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

interface IEditableValueCellProps {
    cell: any;
    colIndex: number;
    readonly: boolean;
    insertNewRow: (newRowIndex: number) => any;
    updateCellValue: (values: any, colIndex: number, rowIndex: number) => any;
}

interface IEditableValueCellState {
    editMode: boolean;
    tempCellValue: string;
    contextMenuId: string;
}

class EditableValueCell extends React.Component<IEditableValueCellProps, IEditableValueCellState> {
    private readonly inputRef: any;

    constructor(p: IEditableValueCellProps) {
        super(p);

        this.state = {
            editMode: false,
            tempCellValue: '',
            contextMenuId: Math.random().toString()
        };

        this.inputRef = React.createRef();
    }

    private getCellValue = (): string => this.props.cell.row.original.values[this.props.colIndex];

    private updateTempCellValue = (e) => this.setState({ tempCellValue: e.target.value });

    private persistCellValue = (nextCellValue: string): void =>
        this.props.updateCellValue(
            nextCellValue,
            this.props.colIndex,
            this.props.cell.row.original.row_index
        );

    private onClick = (e): void => {
        e.stopPropagation();

        if (this.props.readonly || this.state.editMode) {
            return;
        }

        this.setState(
            {
                editMode: true,
                tempCellValue: this.getCellValue()
            },
            () => {
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                }
            }
        );
    };

    private onBlur = (e): void => {
        e.stopPropagation();

        if (this.props.readonly || !this.state.editMode) {
            return;
        }

        if (this.getCellValue() !== this.state.tempCellValue) {
            this.persistCellValue(this.state.tempCellValue);
        }

        this.setState({
            editMode: false,
            tempCellValue: ''
        });
    };

    private onKeyPress = (e) => {
        e.stopPropagation();

        if (e.key === 'Enter' && this.inputRef.current) {
            this.persistCellValue(this.state.tempCellValue);
        }
    };

    private onAddNewRow = (e, newRowIndex: number) => {
        e.stopPropagation();
        this.props.insertNewRow(newRowIndex);
    };

    public render = (): JSX.Element => {
        const { editMode } = this.state;
        const rowIndex: number = this.props.cell.row.original.row_index;
        const currentValue: string = this.getCellValue();
        const editModeClass = editMode ? 'editing' : '';

        return (
            <>
                <ContextMenuTrigger id={this.state.contextMenuId}>
                    <input
                        className={`editable-value-input ${editModeClass}`}
                        ref={this.inputRef}
                        readOnly={!editMode}
                        tabIndex={1}
                        value={!editMode ? currentValue : this.state.tempCellValue}
                        onKeyPress={editMode ? this.onKeyPress : null}
                        onChange={editMode ? this.updateTempCellValue : null}
                        onClick={this.onClick}
                        onFocus={this.onClick}
                        onBlur={this.onBlur}
                    />
                </ContextMenuTrigger>

                <ContextMenu
                    id={this.state.contextMenuId}
                    className='insert-context-menu'>
                    <MenuItem onClick={(e) => this.onAddNewRow(e, rowIndex)}>
                        <Button
                            size='small'
                            style='subtle'>
                            Insert row above
                        </Button>
                    </MenuItem>

                    <MenuItem onClick={(e) => this.onAddNewRow(e, rowIndex + 1)}>
                        <Button
                            size='small'
                            style='subtle'>
                            Insert row below
                        </Button>
                    </MenuItem>
                </ContextMenu>
            </>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            insertNewRow,
            updateCellValue
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(EditableValueCell);
