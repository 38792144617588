import * as React from 'react';
import { AuthGate } from '@imposium-hub/components';
import { browserHistory } from 'react-router';

interface IAuthProps {
    location?: any;
}

export default class Auth extends React.PureComponent<IAuthProps> {
    constructor(p) {
        super(p);
    }

    private onAuthenticated = (): void => {
        browserHistory.replace(`/`);
    };

    public render() {
        const {
            location: {
                hash,
                query: { organization_id }
            }
        } = this.props;

        return (
            <AuthGate
                organizationId={organization_id}
                baseUrl={import.meta.env.VITE_IMPOSIUM_BASE}
                auth0ClientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                auth0Domain={import.meta.env.VITE_AUTH0_DOMAIN}
                auth0Hash={hash}
                onAuthenticated={this.onAuthenticated}
            />
        );
    }
}
