import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editBatchName } from '../../redux/actions/batches';

interface IBatchesTableNameCellProps {
    cell: any;
    editBatchName: (batchId: string, name: string) => any;
}

interface IBatchesTableNameCellState {
    editMode: boolean;
    tempCellValue: string;
}

class BatchesTableNameCell extends React.Component<
    IBatchesTableNameCellProps,
    IBatchesTableNameCellState
> {
    private readonly inputRef: any;

    constructor(p: IBatchesTableNameCellProps) {
        super(p);

        this.state = {
            editMode: false,
            tempCellValue: ''
        };

        this.inputRef = React.createRef();
    }

    private getCellValue = (): string => this.props.cell.row.original.name;

    private updateTempCellValue = (e) => this.setState({ tempCellValue: e.target.value });

    private persistCellValue = (nextCellValue: string): void =>
        this.props.editBatchName(this.props.cell.row.original.id, nextCellValue);

    private onClick = (e): void => {
        e.stopPropagation();

        if (this.state.editMode) {
            return;
        }

        this.setState(
            {
                editMode: true,
                tempCellValue: this.getCellValue()
            },
            () => {
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                }
            }
        );
    };

    private onBlur = (e): void => {
        e.stopPropagation();

        if (!this.state.editMode) {
            return;
        }

        if (this.getCellValue() !== this.state.tempCellValue && this.state.tempCellValue !== '') {
            this.persistCellValue(this.state.tempCellValue);
        }

        this.setState({
            editMode: false,
            tempCellValue: ''
        });
    };

    private onKeyPress = (e) => {
        e.stopPropagation();

        if (e.key === 'Enter' && this.inputRef.current && this.state.tempCellValue !== '') {
            this.persistCellValue(this.state.tempCellValue);
        }
    };

    public render = (): JSX.Element => {
        const { editMode } = this.state;
        const currentValue: string = this.getCellValue();
        const editModeClass = editMode ? 'editing' : '';

        return (
            <input
                className={`editable-value-input batch-name ${editModeClass}`}
                ref={this.inputRef}
                readOnly={!editMode}
                tabIndex={1}
                value={!editMode ? currentValue : this.state.tempCellValue}
                onKeyPress={editMode ? this.onKeyPress : null}
                onChange={editMode ? this.updateTempCellValue : null}
                onClick={this.onClick}
                onFocus={this.onClick}
                onBlur={this.onBlur}
            />
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ editBatchName }, dispatch);
};

export default connect(null, mapDispatchToProps)(BatchesTableNameCell);
