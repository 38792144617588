import * as React from 'react';
import { Spinner } from '@imposium-hub/components';
import { connect } from 'react-redux';

interface IPreviewerFrameProps {
    rendering: boolean;
    media: any;
    playbackSettings: any;
    error?: string;
}

class PreviewerFrame extends React.PureComponent<IPreviewerFrameProps> {
    private readonly videoPlayerRef: any;

    private readonly audioPlayerRef: any;

    constructor(p: IPreviewerFrameProps) {
        super(p);
        this.videoPlayerRef = React.createRef();
    }

    public componentDidMount() {
        if (this.videoPlayerRef.current) {
            this.videoPlayerRef.current.playbackRate = this.props.playbackSettings.rate;
        }
    }

    public componentDidUpdate(prevProps: IPreviewerFrameProps): void {
        if (
            (this.videoPlayerRef.current &&
                this.props.playbackSettings.rate !== prevProps.playbackSettings.rate) ||
            (this.props.media.type === 'video' && this.props.media !== prevProps.media)
        ) {
            this.videoPlayerRef.current.playbackRate = this.props.playbackSettings.rate;
        }
    }

    public render = (): JSX.Element => {
        const {
            rendering,
            media,
            playbackSettings: { autoPlay },
            error
        } = this.props;

        let mediaPreview: JSX.Element = <>{error}</>;

        if (rendering) {
            mediaPreview = (
                <div className='no-preview'>
                    <Spinner />
                </div>
            );
        } else {
            switch (media.type) {
                case 'image':
                    mediaPreview = <img src={media.src} />;
                    break;
                case 'video':
                    mediaPreview = (
                        <video
                            playsInline
                            muted
                            controls
                            ref={this.videoPlayerRef}
                            autoPlay={autoPlay}
                            src={media.src}
                        />
                    );
                    break;
                case 'audio':
                    mediaPreview = (
                        <audio
                            controls
                            ref={this.audioPlayerRef}
                            autoPlay={autoPlay}
                            src={media.src}
                        />
                    );
                default:
                    break;
            }
        }

        return <div className='previewer-frame'>{mediaPreview}</div>;
    };
}

const mapStateToProps = (state): any => {
    return { playbackSettings: state.playbackSettings };
};

export default connect(mapStateToProps, null)(PreviewerFrame);
