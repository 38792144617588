import actions from '../actions/batch-jobs';

const initialState: any = {
    imports: [],
    exports: [],
    duplicates: [],
    renders: {},
    missing: [],
    failed: false
};

const batchJobs = (state = initialState, action): any => {
    let rendersCopy: any;
    switch (action.type) {
        case actions.SET_FAILED:
            return {
                ...state,
                failed: true
            };
        case actions.RESET_FAILED: {
            return {
                ...state,
                failed: false
            };
        }
        case actions.ADD_MISSING:
            return {
                ...state,
                missing: [...state.missing, action.missingColumns]
            };
        case actions.RESET_MISSING:
            return {
                ...state,
                missing: []
            };
        case actions.ADD_JOB:
            return {
                ...state,
                [action.jobKey]: [...state[action.jobKey], action.batchId]
            };
        case actions.REMOVE_JOB:
            return {
                ...state,
                [action.jobKey]: state[action.jobKey].filter(
                    (batchId: string) => batchId !== action.batchId
                )
            };
        case actions.ADD_RENDER_STATUS:
            return {
                ...state,
                renders: {
                    ...state.renders,
                    [action.batchId]: 0
                }
            };
        case actions.UPDATE_RENDER_STATUS:
            return {
                ...state,
                renders: {
                    ...state.renders,
                    [action.batchId]: action.percentComplete
                }
            };
        case actions.REMOVE_RENDER_STATUS:
            rendersCopy = { ...state.renders };
            delete rendersCopy[action.batchId];

            return {
                ...state,
                renders: rendersCopy
            };
        default:
            return state;
    }
};

export default batchJobs;
