import * as React from 'react';
import ColumnHeaderCell from './ColumnHeaderCell';
import ColumnAssociationCell from './ColumnAssociationCell';
import EditableValueCell from './EditableValueCell';
import { DataTable } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBatch } from '../../redux/actions/active-batch';
import { setPage, persistColumns } from '../../redux/actions/active-batch';
import DataSetFilterControls from './DataSetFilterControls';

interface IDataSetTableProps {
    story: any;
    activeBatch: any;
    batchesList: any;
    getBatch: () => any;
    setPage: (page: number) => any;
    persistColumns: () => any;
    status: string;
    experiencesList: any;
}

interface IDataSetTableState {
    columns: any[];
}

class DataSetTable extends React.Component<IDataSetTableProps, IDataSetTableState> {
    private static readonly BATCH_ITEMS_PER_PAGE: number = 25;

    constructor(p: IDataSetTableProps) {
        super(p);

        this.state = {
            columns: []
        };
    }

    public componentDidMount = (): void => {
        if (Object.keys(this.props.story.data).length === 0) {
            return;
        }

        if (
            Object.keys(this.props.activeBatch.data).length > 0 &&
            !this.props.experiencesList.loading
        ) {
            this.props.getBatch();
        }

        if (
            !this.props.activeBatch.data.hasOwnProperty('id') ||
            this.props.batchesList.selected.id !== this.props.activeBatch.data.id
        ) {
            this.props.getBatch().then(this.processBatchResult);
        } else {
            this.processBatchResult();
        }
    };

    public componentDidUpdate = (prevProps: IDataSetTableProps): void => {
        if (
            this.props.story.data.id !== prevProps.story.data.id ||
            this.props.experiencesList.filters !== prevProps.experiencesList.filters
        ) {
            this.props.getBatch();
        }

        if (this.props.activeBatch.page !== prevProps.activeBatch.page) {
            this.props.getBatch();
        }

        if (this.props.batchesList.selected.id !== prevProps.batchesList.selected.id) {
            this.props.setPage(1);
            this.props.getBatch();
        }

        if (this.props.activeBatch.data.columns !== prevProps.activeBatch.data.columns) {
            this.processBatchResult();
        }
    };

    private processBatchResult = (): void => {
        const {
            activeBatch: { data: batchWrapper, associationOptions }
        } = this.props;
        const columns: any[] = [];

        let optKeys: string[];
        let associationOpt: any;

        if (
            typeof batchWrapper === 'object' &&
            batchWrapper.hasOwnProperty('data') &&
            batchWrapper.hasOwnProperty('columns') &&
            Array.isArray(batchWrapper.data) &&
            Array.isArray(batchWrapper.columns)
        ) {
            optKeys = Object.keys(associationOptions);
            for (let i = 0; i < batchWrapper.columns.length; i++) {
                const currColumn: any = batchWrapper.columns[i];
                let association: string = '';

                for (const currAssocType of optKeys) {
                    associationOpt = associationOptions[currAssocType].find(
                        (o: any) => o.value === currColumn.detail
                    );

                    if (
                        currColumn.type === currAssocType &&
                        associationOpt &&
                        associationOpt.hasOwnProperty('value')
                    ) {
                        association = associationOpt.value;
                        break;
                    }
                }

                columns.push({
                    accessor: currColumn.name,
                    Cell: (c) => (
                        <EditableValueCell
                            cell={c}
                            colIndex={i}
                            readonly={currColumn.type === 'EGC'}
                        />
                    ),
                    Header: (c) => (
                        <ColumnHeaderCell
                            cell={c}
                            colIndex={i}
                            headerName={currColumn.name}
                        />
                    ),
                    Filter: (c) => (
                        <ColumnAssociationCell
                            cell={c}
                            colIndex={i}
                            association={association}
                            associationOptions={associationOptions}
                        />
                    ),
                    disableSortBy: true,
                    minWidth: 170
                });
            }

            this.setState({ columns });
        }
    };

    public render = (): JSX.Element => {
        const { columns } = this.state;

        const {
            activeBatch: {
                loading,
                page,
                data: {
                    total_pages: totalPages,
                    total_rows: totalRows,
                    data: rows,
                    columns: batchColumns
                }
            }
        } = this.props;

        let noDataMessage: string;

        if (Array.isArray(batchColumns) && batchColumns.length === 0) {
            noDataMessage = 'Add a new column to get started.';
        }

        if (
            Array.isArray(batchColumns) &&
            batchColumns.length > 0 &&
            Array.isArray(rows) &&
            rows.length === 0
        ) {
            noDataMessage = 'Start adding rows to begin editing your data set.';
        }

        return (
            <div className='data-set-table'>
                <DataSetFilterControls />
                <DataTable
                    data={rows}
                    columns={columns}
                    showInterstitial={loading}
                    totalPages={totalPages}
                    itemsPerPage={DataSetTable.BATCH_ITEMS_PER_PAGE}
                    totalItems={totalRows}
                    currentPage={page}
                    onPage={this.props.setPage}
                    noDataText={noDataMessage}
                />
            </div>
        );
    };
}

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        activeBatch: state.activeBatch,
        batchesList: state.batchesList,
        experiencesList: state.experiencesList
    };
};

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            getBatch,
            setPage,
            persistColumns
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSetTable);
