import { StorageService } from '@imposium-hub/components';

const actions: any = {
    HYDRATE: 'panes/HYDRATE',
    REPOSITION: 'panes/REPOSITION',
    TOGGLE_PANE: 'panes/TOGGLE_PANE',
    RESET: 'panes/RESET'
};

const CACHE_KEY: string = 'exp_manager_panes';

export const getCachedPanesState = (): any => {
    return (dispatch) => {
        StorageService.getItem(CACHE_KEY)
            .then((panesState: any) => {
                dispatch({ type: actions.HYDRATE, panesState });
            })
            .catch((e) => {
                console.error(e);
            });
    };
};

export const reposition = (paneKey: string, position: string | number): any => {
    return (dispatch) => {
        dispatch({ type: actions.REPOSITION, paneKey, position });
        dispatch(persistPanesState());
    };
};

export const togglePreviewer = (toggle: boolean): any => {
    return (dispatch) => {
        dispatch({ type: actions.TOGGLE_PANE, paneKey: 'showPreviewer', toggle });
        dispatch(persistPanesState());
    };
};

export const toggleBatchesTable = (toggle: boolean): any => {
    return (dispatch) => {
        dispatch({ type: actions.TOGGLE_PANE, paneKey: 'showBatchesTable', toggle });
        // dispatch(persistPanesState());
    };
};

export const resetPanesState = (): any => {
    return (dispatch) => {
        dispatch({ type: actions.RESET });
        dispatch(persistPanesState());
    };
};

export const persistPanesState = (): any => {
    return (dispatch, getStore) => {
        const { panes } = getStore();
        void StorageService.setItem(CACHE_KEY, panes);
    };
};

export default actions;
