import actions from '../actions/reject-menu';

const rejectMenu = (state = false, action): any => {
    if (action.type === actions.TOGGLE) {
        return action.toggle;
    } else {
        return state;
    }
};

export default rejectMenu;
