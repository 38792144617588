import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { auth, access, publish } from '@imposium-hub/components';

import panes from './panes';
import story from './active-story';
import batchesList from './batches';
import batchJobs from './batch-jobs';
import experiencesList from './experiences';
import tags from './tags';
import columnMasks from './column-masks';
import activeBatch from './active-batch';
import playbackSettings from './playback-settings';
import rejectMenu from './reject-menu';
import activeTab from './active-tab';

const rootReducer = combineReducers({
    auth,
    access,
    panes,
    story,
    batchesList,
    batchJobs,
    experiencesList,
    tags,
    columnMasks,
    activeBatch,
    playbackSettings,
    rejectMenu,
    activeTab,
    routing: routerReducer,
    publish
});

export default rootReducer;
