import { api } from '../../constants/app';
import { togglePreviewer } from './panes';
import { toggleRejectMenu } from './reject-menu';
import { saveAs } from 'file-saver';
import { getCurrentIndex, calculateNextRow } from '../../util/paging';
import moment from 'moment';

const actions: any = {
    SET_LOADING: 'experiences/SET_LOADING',
    FILTER: 'experiences/FILTER',
    FILTER_LIST: 'experiences/FILTER_LIST',
    FILTER_INVENTORY: 'experiences/FILTER_INVENTORY',
    REMOVE_FILTER_INVENTORY: 'experiences/REMOVE_FILTER_INVENTORY',
    CLEAR_FILTERS: 'experiences/CLEAR_FILTERS',
    SELECT: 'experiences/SELECT',
    SEED_ID: 'experiences/SEED_ID',
    GET: 'experiences/GET',
    DELETE: 'experience/DELETE',
    UPDATE_MODERATION_STATUS: 'experiences/UPDATE_MODERATION_STATUS',
    UPDATE_NOTES: 'experiences/UPDATE_NOTES',
    SET_WIZARD_OPEN: 'experiences/SET_WIZARD_OPEN'
};

export const setLoading = (toggle: boolean): any => {
    return { type: actions.SET_LOADING, toggle };
};

export const setPublishWizard = (toggle: boolean): any => {
    return { type: actions.SET_WIZARD_OPEN, toggle };
};

export const seedExperienceId = (experienceId: string): any => {
    return { type: actions.SEED_ID, experienceId };
};

export const clearExperiencesFilters = (): any => {
    return { type: actions.CLEAR_FILTERS };
};

export const deleteExperience = (experienceId: string): any => {
    return (dispatch) => {
        void api.deleteExperience(experienceId);
        dispatch({ type: actions.DELETE, experienceId });
    };
};

export const blockExperience = (experienceId: string): any => {
    return (dispatch) => {
        api.blockByExperience(experienceId)
            .then((d) => {
                alert(`${d.rejected} Experience(s) moved to REJECTED. User Blocked`);
                dispatch(getExperiences());
            })
            .catch((e) => {
                console.error('Error blocking user.');
            });
    };
};

export const filterExperiencesBy = (filters: any): any => {
    return { type: actions.FILTER, filters };
};

export const filterList = (filters: any): any => {
    return (dispatch, getStore) => {
        const {
            experiencesList: {
                filters: { rendered_only }
            }
        } = getStore();
        const errorFilter = rendered_only === 1 ? 0 : 1;
        dispatch({ type: actions.FILTER_LIST, errorFilter });
    };
};

export const filterExperiencesByInventory = (inventoryItem: string, searchValue: string): any => {
    return (dispatch) => {
        dispatch({ type: actions.FILTER_INVENTORY, inventoryItem, searchValue });
        dispatch(filterExperiencesBy({ page: 1 }));
    };
};

export const removeExperiencesInventoryFilter = (inventoryItem: string): any => {
    return (dispatch) => {
        dispatch({ type: actions.REMOVE_FILTER_INVENTORY, inventoryItem });
        dispatch(filterExperiencesBy({ page: 1 }));
    };
};

export const selectExperience = (selectedExperience: any): any => {
    return (dispatch, getStore) => {
        const {
            panes: { showPreviewer }
        } = getStore();

        if (!showPreviewer && Object.keys(selectedExperience).length > 0) {
            dispatch(togglePreviewer(true));
        }

        dispatch({ type: actions.SELECT, selectedExperience });
    };
};

export const getExperiences = (): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            const {
                story: {
                    data: { id: storyId }
                },
                experiencesList: { filters }
            } = getStore();

            const filtersCopy: any = { ...filters };

            dispatch(setLoading(true));

            for (const key in filtersCopy) {
                if (filtersCopy.hasOwnProperty(key)) {
                    if (!filtersCopy[key]) {
                        delete filtersCopy[key];
                    }

                    if (Array.isArray(filtersCopy[key]) && filtersCopy[key].length > 0) {
                        filtersCopy[key] = filtersCopy[key].join(',');
                    }
                }
            }

            api.getExperiences(storyId, filtersCopy)
                .then((experiences: any) => {
                    dispatch({ type: actions.GET, experiences });
                    dispatch(setLoading(false));
                    resolve();
                })
                .catch((e: Error) => {
                    console.error('Failed to get experiences:', e);
                    dispatch(setLoading(false));
                    reject(e);
                });
        });
    };
};

export const exportExperiences = (): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            const {
                story: {
                    data: { id: storyId }
                },
                experiencesList: { filters }
            } = getStore();

            const filtersCopy: any = { ...filters };

            for (const key in filtersCopy) {
                if (filtersCopy.hasOwnProperty(key)) {
                    if (!filtersCopy[key]) {
                        delete filtersCopy[key];
                    }

                    if (Array.isArray(filtersCopy[key]) && filtersCopy[key].length > 0) {
                        filtersCopy[key] = filtersCopy[key].join(',');
                    }
                }
            }

            api.exportExperiences(storyId, filtersCopy)
                .then((response: any) => {
                    const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
                    const date = new Date();
                    const dateStr = moment(date).format('YYYYMMDD');
                    saveAs(blob, `imposium-export-${dateStr}.csv`);
                })
                .catch((e: Error) => {
                    console.error('Failed to export experiences:', e);
                    dispatch(setLoading(false));
                    reject(e);
                });
        });
    };
};

export const updateModerationStatus = (
    experienceId: string,
    status: string,
    reason?: string
): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            api.updateModerationStatus(experienceId, status)
                .then(() => {
                    const {
                        rejectMenu,
                        experiencesList: {
                            data: { experiences }
                        }
                    } = getStore();

                    if (rejectMenu) {
                        dispatch(toggleRejectMenu(false));
                    }

                    dispatch({
                        type: actions.UPDATE_MODERATION_STATUS,
                        experienceId,
                        status,
                        reason
                    });

                    const currentSelectedIndex: number = getCurrentIndex(
                        experiences,
                        'id',
                        experienceId
                    );
                    const nextRowIndex: number = calculateNextRow(
                        experiences,
                        currentSelectedIndex,
                        'down'
                    );

                    if (currentSelectedIndex !== nextRowIndex) {
                        const experience = experiences[nextRowIndex];
                        dispatch(selectExperience(experience));
                    } else {
                        dispatch(selectExperience({}));
                    }

                    resolve();
                })
                .catch((e: Error) => {
                    console.error('Failed to update moderation status:', e);
                    reject(e);
                });
        });
    };
};

export const updateNotes = (experienceId: string, notes: string): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.addOrReplaceNote(experienceId, notes)
                .then(() => {
                    dispatch({ type: actions.UPDATE_NOTES, experienceId, notes });
                    resolve();
                })
                .catch((e: Error) => {
                    console.error('Failed to update notes:', e);
                    reject(e);
                });
        });
    };
};

export default actions;
