import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { MODERATION_STATUS_COLORS } from '../../constants/misc';
import { BATCHES_COPY as copy } from '../../constants/copy';

interface IBatchesTableModerationStatusProps {
    cell: any;
}

const BatchesTableModerationStatus: React.FC<IBatchesTableModerationStatusProps> = (
    p: IBatchesTableModerationStatusProps
) => {
    const { pending, approved, rejected, used, vip, failed, unrendered } =
        p.cell.row.values.moderation_status;

    let statusRows = 0;

    if (pending) {
        statusRows = statusRows + pending;
    }

    if (approved) {
        statusRows = statusRows + approved;
    }

    if (rejected) {
        statusRows = statusRows + rejected;
    }

    if (used) {
        statusRows = statusRows + used;
    }

    if (vip) {
        statusRows = statusRows + vip;
    }

    if (failed) {
        statusRows = statusRows + failed;
    }

    if (unrendered) {
        statusRows = statusRows + unrendered;
    }

    const pendingPerc = pending
        ? Math.floor(parseFloat((pending / statusRows).toFixed(2)) * 100)
        : 0;
    const approvedPerc = approved
        ? Math.floor(parseFloat((approved / statusRows).toFixed(2)) * 100)
        : 0;
    const rejectedPerc = rejected
        ? Math.floor(parseFloat((rejected / statusRows).toFixed(2)) * 100)
        : 0;
    const usedPerc = used ? Math.floor(parseFloat((used / statusRows).toFixed(2)) * 100) : 0;
    const vipPerc = vip ? Math.floor(parseFloat((vip / statusRows).toFixed(2)) * 100) : 0;
    const failedPerc = failed ? Math.floor(parseFloat((failed / statusRows).toFixed(2)) * 100) : 0;
    const unrenderedPerc = unrendered
        ? Math.floor(parseFloat((unrendered / statusRows).toFixed(2)) * 100)
        : 0;

    const modStatusBar = statusRows !== 0 && (
        <div className='status-bar'>
            <div className='status-bg'>
                {pendingPerc ? (
                    <div
                        className='status-sub-bg pending'
                        style={{ width: `${pendingPerc}%` }}
                    />
                ) : null}
                {approvedPerc ? (
                    <div
                        className='status-sub-bg approved'
                        style={{ width: `${approvedPerc}%` }}
                    />
                ) : null}
                {rejectedPerc ? (
                    <div
                        className='status-sub-bg rejected'
                        style={{ width: `${rejectedPerc}%` }}
                    />
                ) : null}
                {usedPerc ? (
                    <div
                        className='status-sub-bg used'
                        style={{ width: `${usedPerc}%` }}
                    />
                ) : null}
                {vipPerc ? (
                    <div
                        className='status-sub-bg vip'
                        style={{ width: `${vipPerc}%` }}
                    />
                ) : null}
                {failedPerc ? (
                    <div
                        className='status-sub-bg failed'
                        style={{ width: `${failedPerc}%` }}
                    />
                ) : null}
                {unrenderedPerc ? (
                    <div
                        className='status-sub-bg unrendered'
                        style={{ width: `${unrenderedPerc}%` }}
                    />
                ) : null}
            </div>
        </div>
    );

    const drillDown = statusRows !== 0 && (
        <span className='drill-down'>
            {pending ? (
                <p>
                    <FontAwesomeIcon
                        color={MODERATION_STATUS_COLORS.PENDING}
                        icon={faCircle}
                    />
                    &nbsp;{copy.moderationStatus.pending} {pending}
                </p>
            ) : null}
            {approved ? (
                <p>
                    <FontAwesomeIcon
                        color={MODERATION_STATUS_COLORS.APPROVED}
                        icon={faCircle}
                    />
                    &nbsp;{copy.moderationStatus.approved} {approved}
                </p>
            ) : null}
            {rejected ? (
                <p>
                    <FontAwesomeIcon
                        color={MODERATION_STATUS_COLORS.REJECTED}
                        icon={faCircle}
                    />
                    &nbsp;{copy.moderationStatus.rejected} {rejected}
                </p>
            ) : null}
            {used ? (
                <p>
                    <FontAwesomeIcon
                        color={MODERATION_STATUS_COLORS.USED}
                        icon={faCircle}
                    />
                    &nbsp;{copy.moderationStatus.used} {used}
                </p>
            ) : null}
            {vip ? (
                <p>
                    <FontAwesomeIcon
                        color={MODERATION_STATUS_COLORS.VIP}
                        icon={faCircle}
                    />
                    &nbsp;{copy.moderationStatus.vip} {vip}
                </p>
            ) : (
                vip
            )}
            {failed ? (
                <p>
                    <FontAwesomeIcon
                        color={MODERATION_STATUS_COLORS.FAILED}
                        icon={faCircle}
                    />
                    &nbsp;{copy.moderationStatus.failed} {failed}
                </p>
            ) : null}
            {unrendered ? (
                <p>
                    <FontAwesomeIcon
                        color={MODERATION_STATUS_COLORS.UNRENDERED}
                        icon={faCircle}
                    />
                    &nbsp;{copy.moderationStatus.unrendered} {unrendered}
                </p>
            ) : null}
        </span>
    );

    return (
        <div className='mod-status-bar'>
            {modStatusBar}
            {drillDown}
        </div>
    );
};

export default BatchesTableModerationStatus;
