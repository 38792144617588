import { parseInventory } from '../../util/story-parsing-utils';

const actions: any = {
    SET_MASK_CONFIG: 'columnMasks/SET_MASK_CONFIG',
    TOGGLE_MASK: 'columnMasks/TOGGLE_MASKS'
};

export const inventory2ColumnMasks = (): any => {
    return (dispatch, getStore) => {
        const {
            story: { data }
        } = getStore();
        const inventory: any = parseInventory(data);
        const inventoryKeys: string[] = Object.keys(inventory).sort();
        const maskConfig: any[] = inventoryKeys.map((currKey: string) => ({
            id: currKey,
            name: inventory[currKey].name,
            show: true
        }));

        dispatch({ type: actions.SET_MASK_CONFIG, maskConfig });
    };
};

export const toggleColumnMask = (id: string, toggle: boolean): any => {
    return { type: actions.TOGGLE_MASK, id, toggle };
};

export default actions;
