import * as React from 'react';
import { getLargestVideo, secondsToTime } from '../../util/helpers';

interface IExperiencesTableDurationCell {
    cell: any; // React table 'cell' wrapper
}

const ExperiencesTableDurationCell: React.FC<IExperiencesTableDurationCell> = (
    p: IExperiencesTableDurationCell
) => {
    const { row } = p.cell;
    const videos = row?.original?.output?.videos;
    const vid = getLargestVideo(videos);
    if (vid) {
        return <div>{secondsToTime(vid.duration)}</div>;
    } else {
        return null;
    }
};

export default ExperiencesTableDurationCell;
