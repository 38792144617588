import actions from '../actions/column-masks';

const initialState: any[] = [];
const columnMasks = (state = initialState, action): any => {
    switch (action.type) {
        case actions.SET_MASK_CONFIG:
            return [...action.maskConfig];
        case actions.TOGGLE_MASK:
            return state.map((config: any) => {
                if (action.id === config.id) {
                    return {
                        ...config,
                        show: action.toggle
                    };
                }

                return config;
            });
        default:
            return state;
    }
};

export default columnMasks;
