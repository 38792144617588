import * as React from 'react';
import { ButtonGroupField } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateModerationStatus } from '../../redux/actions/experiences';
import { getModerationConfig } from '../ModerationConfig';
import { getBatches } from '../../redux/actions/batches';

interface IExperiencesTableModerationCell {
    cell: any; // React table 'cell' wrapper
    story: any;
    updateModerationStatus: (experienceId: string, status: string) => any;
    getBatches: () => any;
}

class ExperiencesTableModerationCell extends React.PureComponent<IExperiencesTableModerationCell> {
    private static readonly CONTROLS_WIDTH: string = '150px';

    constructor(props: IExperiencesTableModerationCell) {
        super(props);
    }

    private updateModerationStatus = (id, value) => {
        this.props.updateModerationStatus(id, value).then(() => this.props.getBatches());
    };

    public render = (): JSX.Element => {
        const {
            cell: {
                row: {
                    original,
                    values: { moderation_status }
                }
            },
            story: {
                data: { moderationLockApproval }
            }
        } = this.props;

        const unlockAppoval =
            (moderationLockApproval && moderation_status === 'approved') ||
            moderation_status === 'failed';

        return (
            <ButtonGroupField
                width={ExperiencesTableModerationCell.CONTROLS_WIDTH}
                value={moderation_status}
                options={getModerationConfig(moderationLockApproval, moderation_status)}
                onChange={(v) =>
                    !unlockAppoval ? this.updateModerationStatus(original.id, v) : null
                }
                disabled={unlockAppoval}
            />
        );
    };
}

const mapStateToProps = (state): any => {
    return {
        story: state.story
    };
};

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateModerationStatus, getBatches }, dispatch);
};

const ExperiencesTableModerationCellMemoized = connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(ExperiencesTableModerationCell));
export default ExperiencesTableModerationCellMemoized;
