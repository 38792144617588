import * as React from 'react';
import { Button } from '@imposium-hub/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBatchExport } from '../../redux/actions/batch-jobs';
import { BATCHES_COPY } from '../../constants/copy';

interface IBatchesTableDownloadCellProps {
    cell: any;
    exports: any;
    getBatchExport: (batchId: string, batchName: string) => any;
}

class BatchesTableDownloadCell extends React.PureComponent<IBatchesTableDownloadCellProps> {
    constructor(p: IBatchesTableDownloadCellProps) {
        super(p);
    }

    public render = (): JSX.Element => {
        const {
            cell: {
                row: { original }
            },
            exports
        } = this.props;
        const selectedBatchExporting: boolean = exports.includes(original.id);
        const exportable: boolean =
            Number.isInteger(original.total_rows) && original.total_rows > 0;

        return (
            <Button
                key='export'
                size='small'
                style='subtle'
                disabled={!exportable}
                tooltip={BATCHES_COPY.tooltips.tableExport}
                onClick={
                    !selectedBatchExporting
                        ? (e) => {
                              e.stopPropagation();
                              this.props.getBatchExport(original.id, original.name);
                          }
                        : null
                }>
                {!selectedBatchExporting ? (
                    <FontAwesomeIcon icon={faFileDownload} />
                ) : (
                    <FontAwesomeIcon
                        icon={faCog}
                        className='icon-spin-infinite'
                    />
                )}
            </Button>
        );
    };
}

const mapStateToProps = (state) => ({ exports: state.batchJobs.exports });

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getBatchExport }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesTableDownloadCell);
