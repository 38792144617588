import * as React from 'react';
import { getAspectRatio, getLargestVideo } from '../../util/helpers';

interface IExperiencesTableAspectCell {
    cell: any; // React table 'cell' wrapper
}

const ExperiencesTableAspectCell: React.FC<IExperiencesTableAspectCell> = (
    p: IExperiencesTableAspectCell
) => {
    const { row } = p.cell;
    const videos = row?.original?.output?.videos;
    const vid = getLargestVideo(videos);
    if (vid) {
        const aspect = getAspectRatio(vid.width, vid.height);
        return <div>{aspect}</div>;
    } else {
        return null;
    }
};

export default ExperiencesTableAspectCell;
