export const DATE_FORMAT: string = 'M/D/YYYY LT';

export const MODERATION_STATUS_COLORS = {
    PENDING: '#4DD0E1',
    APPROVED: '#81C784',
    REJECTED: '#e57373',
    USED: '#9575CD',
    VIP: '#e6b212',
    FAILED: '#AF1126',
    UNRENDERED: '##5f5f5f'
};
