import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons/faCrown';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { toggleRejectMenu } from '../redux/actions/reject-menu';
import store from '../redux/store';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const PENDING_ICON = (
    <FontAwesomeIcon
        icon={faClock}
        color='#4DD0E1'
    />
);
const APPROVED_ICON = (
    <FontAwesomeIcon
        icon={faCheckCircle}
        color='#81C784'
    />
);
const REJECTED_ICON = (
    <FontAwesomeIcon
        icon={faTimesCircle}
        color='#e57373'
    />
);
const USED_ICON = (
    <FontAwesomeIcon
        icon={faStar}
        color='#9575CD'
    />
);
const VIP_ICON = (
    <FontAwesomeIcon
        icon={faCrown}
        color='#e6b212'
    />
);

const FAILED_ICON = (
    <FontAwesomeIcon
        icon={faCircleExclamation}
        color='#AF1126'
    />
);

export const MODERATION_STATUS_OPTIONS = [
    {
        value: 'pending',
        label: 'Pending',
        icon: PENDING_ICON,
        tooltip: 'Flag as Pending (P)',
        disabled: true
    },
    {
        value: 'approved',
        label: 'Approved',
        icon: APPROVED_ICON,
        tooltip: 'Flag as Approved (A)'
    },
    {
        value: 'used',
        label: 'Used',
        icon: USED_ICON,
        tooltip: 'Flag as Used (U)'
    },
    {
        value: 'vip',
        label: 'VIP',
        icon: VIP_ICON,
        tooltip: 'Flag as VIP (V)'
    },
    {
        value: 'failed',
        label: 'FAILED',
        icon: FAILED_ICON,
        tooltip: 'Flag as Failed (F)'
    },
    {
        value: 'rejected',
        label: 'Rejected',
        icon: REJECTED_ICON,
        onDoubleClick: () => store.dispatch(toggleRejectMenu(true)),
        tooltip: 'Flag as Rejected (R) (double click to specify reason)'
    }
];

export const getModerationConfig = (
    moderationLockApproval: boolean,
    moderationStatus: string
): any[] => {
    return MODERATION_STATUS_OPTIONS.map((o: any) => {
        let tooltip = o.tooltip;

        if (o.value === moderationStatus) {
            tooltip = `Currently flagged as ${o.label}`;
        } else {
            if (moderationLockApproval && moderationStatus === 'approved') {
                tooltip = 'Cannot change status of “Approved” experience';
            } else if (moderationStatus === 'failed') {
                tooltip = 'Cannot change status of “Failed“ experience';
            }
        }

        return {
            ...o,
            tooltip
        };
    });
};
