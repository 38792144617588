import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAssociation } from '../../redux/actions/active-batch';

interface IColumnAssociationCellProps {
    cell: any; // React table 'cell' wrapper
    colIndex: number;
    association: string;
    associationOptions: any;
    updateAssociation: (colIndex: number, newType: string, newAssociation: string) => any;
}

class ColumnAssociationCell extends React.PureComponent<IColumnAssociationCellProps> {
    private static readonly BORDER_HIGHLIGHT_COLORS: any = {
        UGC: '2px solid #9ba049',
        EGC: '2px solid #68a5b5'
    };

    constructor(p: IColumnAssociationCellProps) {
        super(p);
    }

    private onAssociationChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const { associationOptions } = this.props;

        if (e.target.value === '') {
            this.props.updateAssociation(this.props.colIndex, 'Static', e.target.value);
        }

        Object.keys(associationOptions).forEach((typeKey: string) => {
            associationOptions[typeKey].forEach((option: any) => {
                if (option.value === e.target.value) {
                    this.props.updateAssociation(this.props.colIndex, typeKey, e.target.value);
                    return;
                }
            });
        });
    };

    public render = (): JSX.Element => {
        const { association, associationOptions } = this.props;
        const optGroups: JSX.Element[] = [];

        let associationBorder: string;
        let opts: JSX.Element[];

        Object.keys(associationOptions).forEach((typeKey: string) => {
            opts = [];

            associationOptions[typeKey].forEach((associationKVP: any, i: number) => {
                if (associationKVP.value === association) {
                    associationBorder = ColumnAssociationCell.BORDER_HIGHLIGHT_COLORS[typeKey];
                }

                opts.push(
                    <option
                        key={i}
                        value={associationKVP.value}>
                        {associationKVP.label}
                    </option>
                );
            });

            optGroups.push(
                <optgroup
                    key={typeKey}
                    label={typeKey}>
                    {opts}
                </optgroup>
            );
        });

        return (
            <div className='assoc-select'>
                <div className='form-field'>
                    <select
                        value={association}
                        style={{ borderTop: associationBorder }}
                        onChange={this.onAssociationChange}>
                        <option value=''>No Association</option>
                        {optGroups}
                    </select>
                </div>
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateAssociation }, dispatch);
};

export default connect(null, mapDispatchToProps)(ColumnAssociationCell);
