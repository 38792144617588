import * as React from 'react';
import { HRule, ButtonGroupField, SliderField } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    updatePlaybackSpeed,
    toggleAutoPlay,
    toggleLoop
} from '../../redux/actions/playback-settings';
import { PREVIEWER_COPY } from '../../constants/copy';

interface IPlaybackSettingsMenuProps {
    playbackSettings: any;
    updatePlaybackSpeed: (rate: number) => any;
    toggleAutoPlay: (toggle: boolean) => any;
    toggleLoop: (toggle: boolean) => any;
}

class PlaybackSettingsMenu extends React.Component<IPlaybackSettingsMenuProps> {
    private static readonly AUTO_PLAY_OPTIONS: any[] = [
        {
            value: true,
            label: 'On'
        },
        {
            value: false,
            label: 'Off'
        }
    ];

    private static readonly LOOP_OPTIONS: any[] = [
        {
            value: true,
            label: 'On'
        },
        {
            value: false,
            label: 'Off'
        }
    ];

    private static readonly MIN_PLAYBACK_RATE: number = 1.0;

    private static readonly MAX_PLAYBACK_RATE: number = 3.0;

    private static readonly PLAYBACK_STEP: number = 0.5;

    constructor(props: IPlaybackSettingsMenuProps) {
        super(props);
    }

    public render = (): JSX.Element => {
        const { playbackSettings } = this.props;

        return (
            <div className='playback-settings-menu'>
                <p>{PREVIEWER_COPY.playbackSettings.cta}</p>
                <HRule />

                <ButtonGroupField
                    label={PREVIEWER_COPY.playbackSettings.autoPlayLabel}
                    value={playbackSettings.autoPlay}
                    options={PlaybackSettingsMenu.AUTO_PLAY_OPTIONS}
                    onChange={(v) => this.props.toggleAutoPlay(v)}
                />

                <ButtonGroupField
                    label={PREVIEWER_COPY.playbackSettings.loopLabel}
                    value={playbackSettings.loop}
                    options={PlaybackSettingsMenu.LOOP_OPTIONS}
                    onChange={(v) => this.props.toggleLoop(v)}
                />

                <SliderField
                    label={PREVIEWER_COPY.playbackSettings.rateLabel}
                    defaultValue={PlaybackSettingsMenu.MIN_PLAYBACK_RATE}
                    min={PlaybackSettingsMenu.MIN_PLAYBACK_RATE}
                    max={PlaybackSettingsMenu.MAX_PLAYBACK_RATE}
                    step={PlaybackSettingsMenu.PLAYBACK_STEP}
                    value={playbackSettings.rate}
                    onChange={(v) => this.props.updatePlaybackSpeed(v)}
                />
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updatePlaybackSpeed, toggleAutoPlay, toggleLoop }, dispatch);
};

const mapStateToProps = (state): any => {
    return { playbackSettings: state.playbackSettings };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaybackSettingsMenu);
