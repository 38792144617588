import moize from 'moize';

interface IFTCElement {
    width: number;
    height: number;
}

interface IFTCConfig {
    hAlign: string;
    vAlign: string;
    scaleMode: string;
    padding?: number;
    maxWidth?: number;
    maxHeight?: number;
}

export const randomFromArray = (array): any => {
    return array[Math.floor(Math.random() * array.length)];
};

export const isValidEmail = (email): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const fitToContainer = (
    container: IFTCElement,
    content: IFTCElement,
    config: IFTCConfig
): any => {
    let newH;
    let newW;
    let top;
    let left;
    const aspectRatio = content.width / content.height;
    const contWidth = config.padding ? container.width - config.padding * 2 : container.width;
    const contHeight = config.padding ? container.height - config.padding * 2 : container.height;
    const padding = config.padding ? config.padding : 0;
    // scale
    if (config.scaleMode === 'proportionalInside') {
        newW = contWidth;
        newH = newW / aspectRatio;

        if (newH > contHeight) {
            newH = contHeight;
            newW = newH * aspectRatio;
        }
    } else if (config.scaleMode === 'proportionalOutside') {
        newW = contWidth;
        newH = newW / aspectRatio;

        if (newH < contHeight) {
            newH = contHeight;
            newW = newH * aspectRatio;
        }
    } else if (config.scaleMode === 'none' || !config.scaleMode) {
        newW = content.width;
        newH = content.height;
    }

    if (config.maxWidth) {
        if (newW > config.maxWidth) {
            newW = config.maxWidth;
            newH = newW / aspectRatio;
        }
    }

    if (config.maxHeight) {
        if (newH > config.maxHeight) {
            newH = config.maxHeight;
            newW = newH * aspectRatio;
        }
    }

    // fit
    left =
        config.hAlign === 'left'
            ? 0
            : config.hAlign === 'right'
            ? contWidth - newW
            : (contWidth - newW) / 2;
    top =
        config.vAlign === 'top'
            ? 0
            : config.vAlign === 'bottom'
            ? contHeight - newH
            : (contHeight - newH) / 2;

    top += padding;
    left += padding;

    return {
        width: newW,
        height: newH,
        top,
        left
    };
};

export const numberWithCommas = (x): string => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const secondsToTime = (timeInSeconds): string => {
    const minutes: any = Math.floor(timeInSeconds / 60);
    let seconds: any = Math.floor(timeInSeconds - minutes * 60);
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
};

export const getLargestVideo = moize((videos): any => {
    let w;
    let h;
    let largest;
    for (const key in videos) {
        if (videos.hasOwnProperty(key)) {
            const video = videos[key];
            if ((!w && !h) || (videos.width > w && videos.height > h)) {
                largest = video;
                w = video.width;
                h = video.height;
            }
        }
    }
    return largest;
});

export const getAspectRatio = moize((width, height): string => {
    if (width > height) {
        return 'landscape';
    } else if (width < height) {
        return 'portrait';
    } else {
        return 'square';
    }
});
