import { ToastService } from '@imposium-hub/components';
import { saveAs } from 'file-saver';
import { api } from '../../constants/app';
import { forceRenderStatus, forcedRowRendered, getBatches } from './batches';
import { getExperiences } from './experiences';
import { getBatch } from './active-batch';
import { BATCHES_COPY } from '../../constants/copy';

const actions: any = {
    ADD_MISSING: 'batchJobs/ADD_MISSING',
    RESET_MISSING: 'batchJobs/RESET_MISSING',
    ADD_JOB: 'batchJobs/ADD_JOB',
    REMOVE_JOB: 'batchJobs/REMOVE_JOB',
    ADD_RENDER_STATUS: 'batchJobs/ADD_RENDER_STATUS',
    UPDATE_RENDER_STATUS: 'batchJobs/UPDATE_RENDER_STATUS',
    REMOVE_RENDER_STATUS: 'batchJobs/REMOVE_RENDER_STATUS',
    SET_FAILED: 'batchJobs/SET_FAILED',
    RESET_FAILED: 'batchJobs/RESET_FAILED'
};

const EXPORTS_JOB_KEY: string = 'exports';
const DUPLICATES_JOB_KEY: string = 'duplicates';
const NO_EXPORT_MESSAGE: string = 'No exports available';
const JOB_COMPLETE_STATUS: string = 'completed';
const JOB_POLL_INTERVAL: number = 1000;
const RENDER_CHECK_POLL_INTERVAL: number = 5000;
const RENDER_COMPLETE_PERC: number = 100;

// export const resumePollingFileJobs = () :

export const importBatchFromCsv = (
    storyId: string,
    batchId: string,
    csvFile: File,
    accessKey: string,
    compositionId: string,
    addEmbed?: boolean,
    addMedia?: boolean
): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.getSignedUrl(storyId, csvFile)
                .then((resSignedUrl) => {
                    api.uploadToSignedUrl(resSignedUrl.signed_url, csvFile)
                        .then(() => {
                            api.importBatchDataFromCsv(
                                batchId,
                                resSignedUrl.file_key,
                                addEmbed,
                                accessKey,
                                compositionId,
                                addMedia
                            )
                                .then((importStatus: any) => {
                                    const { job_id: jobId, missing_columns } = importStatus;

                                    if (missing_columns && missing_columns !== undefined) {
                                        const missingColumns: string[] = Object.keys(
                                            missing_columns
                                        ).map((c) => {
                                            return missing_columns[c];
                                        });

                                        dispatch({ type: actions.ADD_MISSING, missingColumns });
                                        resolve(importStatus);
                                    } else {
                                        dispatch({ type: actions.RESET_MISSING });
                                        doPollJobCompletionStatus(jobId)
                                            .then(() => {
                                                dispatch(getBatches());
                                                resolve();
                                            })
                                            .catch((e: Error) => {
                                                reject(e);
                                                throw e;
                                            });
                                    }
                                })
                                .catch((e: Error) => {
                                    reject(e);
                                    throw e;
                                });
                        })
                        .catch((e: Error) => {
                            reject(e);
                            throw e;
                        });
                })
                .catch((e: Error) => {
                    reject(e);
                });
        });
    };
};

export const renderBatch = (batchId: string, postRenderActions?: any): any => {
    let updateDelegate: (p: number) => void;
    let updateRowRendered: (p: number) => void;
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            dispatch({ type: actions.RESET_FAILED });
            dispatch({ type: actions.ADD_RENDER_STATUS, batchId });

            api.prepareBatchForRender(batchId)
                .then((res) => {
                    api.invokeBatchRenderJob(batchId, postRenderActions)
                        .then((resp) => {
                            updateDelegate = (p) => dispatch(updateRenderStatus(batchId, p));
                            updateRowRendered = (p) => dispatch(forcedRowRendered(batchId, p));
                            dispatch(forceRenderStatus(batchId, 'rendering'));
                            const {
                                batchesList: {
                                    selected,
                                    selected: { name: batchName }
                                }
                            } = getStore();

                            ToastService.emit('info', `Rendering Batch: ${batchName}`);

                            dispatch(
                                doPollRenderCompletionStatus(
                                    batchId,
                                    updateDelegate,
                                    updateRowRendered
                                )
                            )
                                .then(() => {
                                    ToastService.emit(
                                        'info',
                                        `Batch: ${batchName} has finished rendering`
                                    );

                                    if (selected.id === batchId) {
                                        dispatch(getBatch());
                                    }

                                    dispatch(getBatches()).then(() => {
                                        dispatch(forceRenderStatus(batchId, 'rendered'));
                                    });

                                    dispatch(getExperiences());
                                    dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                                    resolve();
                                })
                                .catch((e: Error) => {
                                    // TO DO: should emit a toast here after failing to expo retry in doPoll...
                                    // saying the check on render processes failed / plz refresh list to resume
                                    if (e) {
                                        console.error('Failed to poll for batch status:', e);
                                        dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                                        reject(e);
                                    }
                                });
                        })
                        .catch((e) => {
                            const { status, data } = e.response;
                            if (status === 402) {
                                dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                                ToastService.emit('error', `Usage: ${data.error}`);
                            }
                            reject(e);
                        });
                })
                .catch((e: Error) => {
                    console.error('Failed to start rendering batch:', e);
                    dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                    reject(e);
                });
        });
    };
};

export const resumePollingRenderStatus = (batchId: string): any => {
    return (dispatch) => {
        let timeout: number;

        const runPoll = (): void => {
            clearTimeout(timeout);
            api.getBatchProgress(batchId)
                .then((progressStatus: any) => {
                    const { status, rows_rendered, total_rows } = progressStatus;
                    if (
                        rows_rendered === total_rows &&
                        status === BATCHES_COPY.progress.rendering
                    ) {
                        clearTimeout(timeout);
                        dispatch(forceRenderStatus(batchId, 'rendered'));
                    } else if (
                        status === BATCHES_COPY.progress.rendered ||
                        status === BATCHES_COPY.progress.failed
                    ) {
                        clearTimeout(timeout);
                        dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                        dispatch(getBatches());
                    } else {
                        timeout = window.setTimeout(() => runPoll(), RENDER_CHECK_POLL_INTERVAL);
                    }
                })
                .catch((e: Error) => {
                    console.error(e);
                });
        };

        runPoll();
    };
};

export const updateRenderStatus = (batchId: string, p: number): any => {
    return (dispatch, getStore) => {
        const {
            batchJobs,
            batchesList: { selected }
        } = getStore();
        const currentPerc: any = batchJobs.renders[batchId];

        if (p > currentPerc) {
            dispatch({ type: actions.UPDATE_RENDER_STATUS, percentComplete: p, batchId });

            if (batchId === selected.id) {
                dispatch(getBatch());
                dispatch(getExperiences());
            }
        }
    };
};

export const cancelBatchRenderJob = (batchId: string): any => {
    return (dispatch) => {
        api.cancelBatchRenderJob(batchId)
            .then(() => {
                ToastService.emit('info', `Renders for batch: ${batchId} have been cancelled`);

                dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                dispatch(getBatches()).then(() => {
                    dispatch(forceRenderStatus(batchId, 'cancelled'));
                });
            })
            .catch((e) => {
                console.error('Failed to cancel batch render job:', e);
            });
    };
};

export const duplicateBatch = (batchId: string, batchName: string): any => {
    return (dispatch) => {
        dispatch({ type: actions.ADD_JOB, jobKey: DUPLICATES_JOB_KEY, batchId });

        api.duplicateBatch(batchId)
            .then((jobDetails: any) => {
                const { job_id: jobId } = jobDetails;

                ToastService.emit('info', `Duplicating batch: ${batchName}`);

                dispatch(getBatches());
                doPollJobCompletionStatus(jobId)
                    .then(() => {
                        ToastService.emit('info', `Finished duplicating batch: ${batchName}`);

                        dispatch({ type: actions.REMOVE_JOB, jobKey: DUPLICATES_JOB_KEY, batchId });
                        dispatch(getBatches());
                        dispatch(getBatch());
                    })
                    .catch((e: Error) => {
                        throw e;
                    });
            })
            .catch((e: Error) => {
                ToastService.emit('error', `Error duplicating batch: ${batchName}`);

                dispatch({ type: actions.REMOVE_JOB, jobKey: DUPLICATES_JOB_KEY, batchId });
                console.error('Failed to export batch data:', e);
            });
    };
};

export const getBatchExport = (batchId: string): any => {
    return (dispatch, getState) => {
        const state = getState();
        return new Promise<void>((resolve, reject) => {
            dispatch({ type: actions.ADD_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
            api.checkBatchExportStatus(batchId)
                .then(async (exportStatus: any) => {
                    if (exportStatus.hasOwnProperty('id')) {
                        await downloadExport(batchId, exportStatus.id, state, dispatch);
                        resolve();
                    }

                    if (
                        !exportStatus.hasOwnProperty('id') &&
                        exportStatus.message === NO_EXPORT_MESSAGE
                    ) {
                        await processNewBatchExport(batchId, state, dispatch);
                        resolve();
                    }
                })
                .catch((e: Error) => {
                    dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
                    reject(e);
                });
        });
    };
};

const processNewBatchExport = (batchId: string, state, dispatch): any => {
    return new Promise<void>((resolve, reject) => {
        api.invokeBatchExportJob(batchId)
            .then((jobDetails: any) => {
                const { job_id: jobId } = jobDetails;

                doPollJobCompletionStatus(jobId)
                    .then(() => {
                        api.checkBatchExportStatus(batchId)
                            .then(async (exportStatus: any) => {
                                await downloadExport(batchId, exportStatus.id, state, dispatch);
                                resolve();
                            })
                            .catch((e: Error) => {
                                reject();
                                throw e;
                            });
                    })
                    .catch((e: Error) => {
                        reject();
                        throw e;
                    });
            })
            .catch((e: Error) => {
                dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
            });
    });
};

const downloadExport = (batchId: string, exportId: number, state, dispatch): any => {
    return new Promise<void>((resolve, reject) => {
        const {
            batchesList: {
                data: { batches }
            }
        } = state;
        const selectedBatch: any = batches.find((b: any) => b.id === batchId);

        api.downloadBatchExport(batchId, exportId)
            .then((batchBlob: Blob) => {
                saveAs(batchBlob, `${selectedBatch.name}.csv`);
                dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
                resolve();
            })
            .catch((e: Error) => {
                dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
                reject();
            });
    });
};
const doPollRenderCompletionStatus = (
    batchId: string,
    onUpdate: (perc: number) => void,
    onRowRender: (rendered: number) => void
): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            let timeout: number;

            const runPoll = (): void => {
                const { batchJobs } = getStore();

                clearTimeout(timeout);

                if (!batchJobs.renders.hasOwnProperty(batchId)) {
                    return reject();
                }

                api.getBatchProgress(batchId)
                    .then((progressStatus: any) => {
                        const {
                            rows_rendered: rowsRendered,
                            total_rows: totalRows,
                            status
                        } = progressStatus;

                        const percentComplete: number = Math.floor(
                            (rowsRendered / totalRows) * 100
                        );

                        if (status === BATCHES_COPY.progress.rendering) {
                            onRowRender(rowsRendered);
                        }

                        if (
                            (percentComplete === RENDER_COMPLETE_PERC &&
                                status === BATCHES_COPY.progress.rendering) ||
                            status === BATCHES_COPY.progress.rendered ||
                            status === BATCHES_COPY.progress.cancelled
                        ) {
                            resolve();
                        } else if (status === BATCHES_COPY.progress.failed) {
                            dispatch({ type: actions.SET_FAILED });
                            resolve();
                        } else {
                            if (status !== BATCHES_COPY.progress.queued) {
                                onUpdate(percentComplete);
                            }

                            timeout = window.setTimeout(
                                () => runPoll(),
                                RENDER_CHECK_POLL_INTERVAL
                            );
                        }
                    })
                    .catch((e: Error) => {
                        reject(e);
                    });
            };

            runPoll();
        });
    };
};

const doPollJobCompletionStatus = (jobId: string): any => {
    return new Promise<void>((resolve, reject) => {
        let timeout: number;

        const runPoll = (): void => {
            clearTimeout(timeout);

            api.getJob(jobId)
                .then((job: any) => {
                    if (!job.processing && job.status === JOB_COMPLETE_STATUS) {
                        resolve();
                    } else {
                        timeout = window.setTimeout(() => runPoll(), JOB_POLL_INTERVAL);
                    }
                })
                .catch((e: Error) => {
                    reject(e);
                });
        };

        runPoll();
    });
};

export default actions;
