import * as React from 'react';
import SplitPane from 'react-split-pane';
import BatchesTable from './batches/BatchesTable';
import TabbedSection from './TabbedSection';
import Previewer from './previewer/Previewer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reposition, resetPanesState } from '../redux/actions/panes';
import { throttle } from 'lodash';

interface IPaneViewProps {
    access: any;
    panes: any;
    organizationId: string;
    batchId: string;
    reposition: (paneKey: string, position: string | number) => any;
    resetPanesState: () => any;
}

interface IPaneViewState {
    tableMaxHeight: number;
    previewMaxWidth: number;
    hasBatchAccess: boolean;
}

class PaneView extends React.Component<IPaneViewProps, IPaneViewState> {
    private static readonly MAIN_HEADER_OFFSET: number = 54;

    private static readonly TABLE_PANE_KEY: string = 'tablePaneSize';

    private static readonly TABLE_MIN_HEIGHT: number = 25;

    private static readonly PREVIEW_PANE_KEY: string = 'previewPaneSize';

    private static readonly PREVIEW_PANE_MIN_SIZE: number = 480;

    private static readonly PREVIEW_PANE_HIDDEN_WIDTH: string = '0%';

    private readonly ON_RESIZE: () => void = () => this.calculateTablesMaxHeight();

    constructor(p: IPaneViewProps) {
        super(p);

        const hasBatchAccess: boolean = this.checkForBatchAccess();
        if (!hasBatchAccess && this.props.panes.tablePaneSize > 0) {
            this.props.reposition(PaneView.TABLE_PANE_KEY, 0);
        }

        this.state = {
            tableMaxHeight: Math.floor(window.innerHeight - PaneView.MAIN_HEADER_OFFSET),
            previewMaxWidth: Math.floor(window.innerWidth / 2),
            hasBatchAccess
        };
    }

    public componentDidMount = (): void => {
        window.addEventListener('resize', this.ON_RESIZE);
    };

    public componentDidUpdate = (prevProps: IPaneViewProps): void => {
        let hasBatchAccessFresh: boolean;

        if (!this.props.panes.showBatchesTable && prevProps.panes.showBatchesTable) {
            this.props.reposition(PaneView.TABLE_PANE_KEY, 0);
        }

        if (this.props.panes.showBatchesTable && !prevProps.panes.showBatchesTable) {
            this.props.reposition(PaneView.TABLE_PANE_KEY, this.state.tableMaxHeight / 1.66);
        }

        if (this.props.organizationId !== prevProps.organizationId) {
            hasBatchAccessFresh = this.checkForBatchAccess();

            if (!this.state.hasBatchAccess && hasBatchAccessFresh) {
                this.props.resetPanesState();
            }

            if (this.state.hasBatchAccess && !hasBatchAccessFresh) {
                this.props.reposition(PaneView.TABLE_PANE_KEY, 0);
            }

            this.setState({ hasBatchAccess: hasBatchAccessFresh });
        }
    };

    public componentWillUnmount = (): void => {
        window.removeEventListener('resize', this.ON_RESIZE);
    };

    private checkForBatchAccess = (): boolean => {
        const activeOrg: any = this.props.access.organizations.find(
            (o: any) => o.id === this.props.organizationId
        );

        return activeOrg?.services.includes(4) && activeOrg?.services.includes(5);
    };

    private calculateTablesMaxHeight = (): void => {
        const tableMaxHeight: number = Math.floor(window.innerHeight - PaneView.MAIN_HEADER_OFFSET);
        const previewMaxWidth: number = Math.floor(window.innerWidth / 2);

        if (this.props.panes.previewPaneSize > previewMaxWidth) {
            this.props.reposition(PaneView.PREVIEW_PANE_KEY, previewMaxWidth);
        }

        this.setState({ tableMaxHeight, previewMaxWidth });
    };

    public render() {
        const { tableMaxHeight, previewMaxWidth, hasBatchAccess } = this.state;
        const {
            panes: { tablePaneSize, previewPaneSize, showBatchesTable, showPreviewer },
            batchId
        } = this.props;

        return (
            <SplitPane
                split='vertical'
                primary='second'
                allowResize={showPreviewer}
                size={showPreviewer ? previewPaneSize : PaneView.PREVIEW_PANE_HIDDEN_WIDTH}
                minSize={PaneView.PREVIEW_PANE_MIN_SIZE}
                maxSize={previewMaxWidth}
                onChange={throttle(
                    (size) => this.props.reposition(PaneView.PREVIEW_PANE_KEY, Math.floor(size)),
                    200
                )}>
                <SplitPane
                    split='horizontal'
                    primary='second'
                    size={tablePaneSize}
                    minSize={PaneView.TABLE_MIN_HEIGHT}
                    maxSize={tableMaxHeight}
                    allowResize={showBatchesTable}
                    onChange={throttle(
                        (size) => this.props.reposition(PaneView.TABLE_PANE_KEY, Math.floor(size)),
                        200
                    )}>
                    {showBatchesTable && hasBatchAccess && <BatchesTable batchId={batchId} />}
                    <TabbedSection hasBatchAccess={hasBatchAccess} />
                </SplitPane>

                {showPreviewer ? <Previewer /> : <></>}
            </SplitPane>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ reposition, resetPanesState }, dispatch);
};

const mapStateToProps = (state): any => {
    return { access: state.access, panes: state.panes };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaneView);
