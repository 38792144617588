import actions, { BATCH_ITEMS_PER_PAGE } from '../actions/active-batch';

const initialState: any = {
    loading: true,
    updating: false,
    associationOptions: {},
    data: {}
};

const FRESH_COLUMN: any = {
    name: 'New Column',
    type: 'Static',
    from_import: 1,
    detail: null
};

const getNFreshColumns = (state: any) => {
    return state.data.columns.filter((col: any) => col.name.includes(FRESH_COLUMN.name)).length;
};

const activeBatch = (state = initialState, action): any => {
    let tmpBatchData: any[];
    let nFreshCols: number;
    let freshCol: any;

    switch (action.type) {
        case actions.TOGGlE_LOADING:
            return {
                ...state,
                loading: action.toggle
            };
        case actions.TOGGLE_UPDATING:
            return {
                ...state,
                updating: action.toggle
            };
        case actions.ADD_COLUMN:
            if (typeof action.columnData !== 'undefined') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        columns: [...state.data.columns, action.columnData]
                    }
                };
            } else {
                nFreshCols = getNFreshColumns(state);
                if (!nFreshCols) {
                    freshCol = { ...FRESH_COLUMN };
                } else {
                    freshCol = { ...FRESH_COLUMN, name: FRESH_COLUMN.name + ` (${nFreshCols})` };
                }

                return {
                    ...state,
                    data: {
                        ...state.data,
                        columns: [...state.data.columns, freshCol]
                    }
                };
            }
        case actions.REPLACE_ASSOCIATION_OPTIONS:
            return {
                ...state,
                associationOptions: { ...action.associationOptions }
            };
        case actions.UPDATE_NAME:
            return {
                ...state,
                data: {
                    ...state.data,
                    columns: state.data.columns.map((col: any, i: number) => {
                        if (action.colIndex === i) {
                            return {
                                ...col,
                                name: action.newName
                            };
                        }

                        return col;
                    })
                }
            };
        case actions.UPDATE_ASSOCIATION:
            return {
                ...state,
                data: {
                    ...state.data,
                    columns: state.data.columns.map((col: any, i: number) => {
                        if (action.colIndex === i) {
                            if (action.newType === 'Static') {
                                return {
                                    ...col,
                                    type: action.newType,
                                    detail: null
                                };
                            } else {
                                return {
                                    ...col,
                                    type: action.newType,
                                    detail: action.newAssociation,
                                    from_import: action.newType === 'EGC' ? 0 : 1
                                };
                            }
                        }

                        return col;
                    })
                }
            };
        case actions.EDIT_CELL_VALUE:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: state.data.data.map((col: any, i: number) => {
                        if (action.rowIndex !== i) {
                            return col;
                        }

                        return {
                            ...col,
                            values: col.values.map((value: string, j: number) => {
                                if (action.colIndex !== j) {
                                    return value;
                                }

                                return action.value;
                            })
                        };
                    })
                }
            };
        case actions.INSERT_ROW:
            tmpBatchData = [...state.data.data];
            tmpBatchData.splice(action.rowIndex, 0, {
                values: Array(state.data.columns.length).fill(''),
                row_index: action.rowIndex,
                experience_id: null,
                status: null
            });

            if (tmpBatchData.length > BATCH_ITEMS_PER_PAGE) {
                tmpBatchData.pop();
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    total_pages: Math.ceil(action.freshTotal / BATCH_ITEMS_PER_PAGE),
                    total_rows: action.freshTotal,
                    row_count: action.freshTotal,
                    data: tmpBatchData.map((row, i) => {
                        if (i <= action.rowIndex) {
                            return row;
                        }
                        if (i > action.rowIndex) {
                            return {
                                ...row,
                                row_index: row.row_index + 1
                            };
                        }
                    })
                }
            };
        case actions.REMOVE_COLUMN:
            return {
                ...state,
                data: {
                    ...state.data,
                    columns: state.data.columns.filter((_, i) => i !== action.colIndex)
                }
            };
        case actions.REPLACE_COLUMNS:
            return {
                ...state,
                data: {
                    ...state.data,
                    columns: action.columns
                }
            };
        case actions.SET_BATCH:
            return {
                ...state,
                data: { ...action.batch }
            };
        case actions.SET_PAGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: action.page
                }
            };
        default:
            return state;
    }
};

export default activeBatch;
