import * as React from 'react';
import { SelectField, Button } from '@imposium-hub/components';
import { PREVIEWER_COPY, ERROR } from '../../constants/copy';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';

interface IPreviewerFrameTabsProps {
    activeOutputKeys: string[];
    outputKeys: [string, string][]; // Flattened array of media type / key tuples for lookup
    onSelectMedia: (typeKey: string, mediaKey: string) => void;
    selected: any;
}

export default class OutputSelector extends React.PureComponent<IPreviewerFrameTabsProps> {
    constructor(p: IPreviewerFrameTabsProps) {
        super(p);
    }

    private formatMediaKeyLabel = (mediaKey: string): string => {
        if (mediaKey.includes('mp4')) {
            return `${PREVIEWER_COPY.output.videoPrefix} [${mediaKey.split('_')[1]}p]`;
        }

        if (mediaKey === 'poster') {
            return PREVIEWER_COPY.output.poster;
        }

        if (mediaKey === 'snapshot') {
            return PREVIEWER_COPY.output.snapshot;
        }

        if (mediaKey === 'social') {
            return PREVIEWER_COPY.output.social;
        }

        return mediaKey;
    };

    private onOutputSelectChanged = (keys: string) => {
        const [typeKey, mediaKey] = keys.split(':');
        this.props.onSelectMedia(typeKey, mediaKey);
    };

    private download = async (url, fileName) => {
        const res = await fetch(url, {
            cache: 'no-cache'
        });
        const blob = await res.blob();

        saveAs(blob, fileName);
    };

    public render = (): JSX.Element => {
        const {
            activeOutputKeys,
            outputKeys,
            selected: { id, output }
        } = this.props;
        const [activeTypeKey, activeMediaKey] = activeOutputKeys;

        let downloadLink: string;
        let fileName: string;

        if (output && output[activeTypeKey] && output[activeTypeKey][activeMediaKey]) {
            const activeOutput = output[activeTypeKey][activeMediaKey];
            if (activeOutput) {
                if (activeTypeKey === 'images') {
                    downloadLink = activeOutput.url;
                    fileName = `${activeMediaKey}_${id}.${activeOutput.format}`;
                } else if (activeTypeKey === 'videos') {
                    downloadLink = activeOutput.url;
                    fileName = `${activeMediaKey}_${id}.${activeOutput.format}`;
                } else if (activeTypeKey === 'audio') {
                    downloadLink = activeOutput.url;
                    fileName = `${activeMediaKey}_${id}.${activeOutput.format}`;
                }
            }
        }

        const btnDownload = downloadLink ? (
            <Button
                color='default'
                style='subtle'
                size='small'
                onClick={() => {
                    this.download(downloadLink, fileName).catch((error) =>
                        alert(`${ERROR.download.replace('[error]', error)}`)
                    );
                }}>
                <FontAwesomeIcon icon={faDownload} />
            </Button>
        ) : null;

        const options: any = outputKeys
            .map((outputs) => {
                const [typeKey, mediaKey] = outputs;

                if (Object.keys(output).length === 0) return null;

                if (mediaKey === 'm3u8') return null;

                if (output[typeKey] === undefined) return null;

                const o = output[typeKey][mediaKey];

                let name;
                let format;
                let url;

                if (o === undefined) return null;

                if (typeof o === 'string' || o instanceof String) {
                    url = o;
                } else {
                    name = o.name;
                    format = o.format;
                    url = o.url;
                }

                const getName = name
                    ? name
                    : url.substring(url.lastIndexOf('_') + 1, url.lastIndexOf('.'));

                let label;

                if (output[typeKey][mediaKey]) label = getName ? getName : format;

                return {
                    label,
                    value: `${typeKey}:${mediaKey}`
                };
            })
            .filter((i) => i !== null);

        const outputSelect =
            options.length !== 0 ? (
                <>
                    <SelectField
                        label=''
                        width='140px'
                        value={`${activeTypeKey}:${activeMediaKey}`}
                        onChange={this.onOutputSelectChanged}
                        options={options}
                    />
                    {btnDownload}
                </>
            ) : null;
        return <div className='previewer-frame-select'>{outputSelect}</div>;
    };
}
