import actions from '../actions/playback-settings';

const initialState: any = {
    rate: 1.0,
    autoPlay: true,
    selectedVersions: {},
    loop: false
};

const playbackSettings = (state = initialState, action): any => {
    switch (action.type) {
        case actions.UPDATE_SETTING:
            return {
                ...state,
                [action.setting]: action.value
            };
        default:
            return state;
    }
};

export default playbackSettings;
