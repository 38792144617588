import actions from '../actions/active-story';

// loading to be set true until caching of story data in place
const initialState: any = {
    loading: true,
    data: {}
};

const activeStory = (state = initialState, action): any => {
    switch (action.type) {
        case actions.TOGGlE_LOADING:
            return {
                ...state,
                loading: action.toggle
            };
        case actions.SET_STORY:
            return {
                ...state,
                data: { ...action.story }
            };
        default:
            return state;
    }
};

export default activeStory;
