import * as React from 'react';
import { Button } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateColumnName, removeColumn } from '../../redux/actions/active-batch';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

interface IColumnHeaderCellProps {
    cell: any; // React table 'cell' wrapper
    colIndex: number;
    headerName: string;
    updateColumnName: (colIndex: number, newName: string) => any;
    removeColumn: (colIndex: number) => any;
}

interface IColumnHeaderCellState {
    editMode: boolean;
    tempHeaderValue: string;
}

class ColumnHeaderCell extends React.Component<IColumnHeaderCellProps, IColumnHeaderCellState> {
    private readonly inputRef: any;

    constructor(p: IColumnHeaderCellProps) {
        super(p);

        this.state = {
            editMode: false,
            tempHeaderValue: ''
        };

        this.inputRef = React.createRef();
    }

    private updateTempCellValue = (e) => this.setState({ tempHeaderValue: e.target.value });

    private persistHeaderValue = (newHeaderName: string): void =>
        this.props.updateColumnName(this.props.colIndex, newHeaderName);

    private onClick = (e): void => {
        e.stopPropagation();

        if (this.state.editMode) {
            return;
        }

        this.setState(
            {
                editMode: true,
                tempHeaderValue: this.props.headerName
            },
            () => {
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                }
            }
        );
    };

    private onBlur = (e): void => {
        e.stopPropagation();

        if (!this.state.editMode) {
            return;
        }

        if (this.props.headerName !== this.state.tempHeaderValue) {
            this.persistHeaderValue(this.state.tempHeaderValue);
        }

        this.setState({
            editMode: false,
            tempHeaderValue: ''
        });
    };

    private onKeyPress = (e) => {
        e.stopPropagation();

        if (e.key === 'Enter' && this.inputRef.current) {
            this.persistHeaderValue(this.state.tempHeaderValue);
        }
    };

    private doDeleteColumn = (e): void => {
        e.stopPropagation();

        if (confirm(`Are you sure you want to delete column: ${this.props.headerName}?`)) {
            this.props.removeColumn(this.props.colIndex);
        }
    };

    public render = (): JSX.Element => {
        const { editMode } = this.state;
        const { headerName } = this.props;
        const editModeClass = editMode ? 'editing' : '';

        return (
            <>
                <ContextMenuTrigger id={headerName}>
                    <input
                        className={`editable-value-input header ${editModeClass}`}
                        ref={this.inputRef}
                        readOnly={!editMode}
                        tabIndex={1}
                        value={!editMode ? headerName : this.state.tempHeaderValue}
                        onKeyPress={editMode ? this.onKeyPress : null}
                        onChange={editMode ? this.updateTempCellValue : null}
                        onClick={this.onClick}
                        onFocus={this.onClick}
                        onBlur={this.onBlur}
                    />
                </ContextMenuTrigger>

                <ContextMenu
                    id={headerName}
                    className='insert-context-menu'>
                    <MenuItem onClick={(e) => this.doDeleteColumn(e)}>
                        <Button
                            size='small'
                            style='subtle'>
                            Delete Column
                        </Button>
                    </MenuItem>
                </ContextMenu>
            </>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateColumnName, removeColumn }, dispatch);
};

export default connect(null, mapDispatchToProps)(ColumnHeaderCell);
