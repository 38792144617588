import actions from '../actions/tags';

const initialState: any[] = [];

const tags = (state = initialState, action): any => {
    switch (action.type) {
        case actions.ADD_TAG:
            return [...state, action.tag];
        case actions.ADD_TAGS:
            return action.updateTags;
        case actions.REMOVE_TAG:
            return state.filter((_, i) => i !== action.tagIndex);
        case actions.UPDATE_TAGS:
            return [...action.updateTags];
        case actions.RESET_TAGS:
            return [];
        case actions.SELECT_TAG:
            return [action.tag];
        default:
            return state;
    }
};

export default tags;
