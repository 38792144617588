import { Modal, HEADER_HEIGHT, Button } from '@imposium-hub/components';

interface IRowErrorModal {
    setErrorModal(a: boolean, i: any): void;
    selectTag(tag: any): any;
    addTag(tag: any): any;
    batchesList: any;
}

export const RowErrorModal = (p: IRowErrorModal) => {
    const {
        batchesList: {
            errorModal: { isOpen, rowId },
            selected: { moderation_status, name },
            error
        }
    } = p;

    let errorMsg: JSX.Element = <></>;
    let errorExpMsg: JSX.Element = <></>;
    if (error[rowId]) {
        const { experience } = error[rowId];
        errorMsg = <span className='error-title'>{error[rowId].error}</span>;
        errorExpMsg = experience?.error ? <div>{experience.error}</div> : <></>;
    }

    const failedTag = { id: 'moderation:failed', name: 'Moderaton Status: Failed' };

    return (
        <Modal
            onRequestClose={() => p.setErrorModal(!isOpen, '')}
            wrapperStyle={{
                top: HEADER_HEIGHT,
                left: '0px',
                width: '100%',
                height: `calc(100% - ${HEADER_HEIGHT}px)`
            }}
            style={{
                width: '600px',
                height: '200px',
                top: '10%',
                left: 'calc((100% - 600px) / 2)'
            }}
            isOpen={isOpen}>
            <div className='batch-error-modal'>
                <h2>{`Batch "${name}" stopped due to errors.`}</h2>
                <br />
                <br />
                <table>
                    <tr>
                        <td>Batch ID:</td>
                        <td>{rowId}</td>
                    </tr>
                    <tr>
                        <td>Failed Rows:</td>
                        <td>{moderation_status.failed}</td>
                    </tr>
                    <tr>
                        <td>First Error:</td>
                        <td>
                            {errorMsg}
                            <br />
                            {errorExpMsg}
                        </td>
                    </tr>
                </table>
                <Button
                    key='view_failed_experience'
                    customStyles={{
                        position: 'absolute',
                        bottom: '3px',
                        left: '50%',
                        marginLeft: '-60px'
                    }}
                    onClick={() => {
                        p.addTag(failedTag);
                        p.selectTag(failedTag);
                        p.setErrorModal(!isOpen, '');
                    }}
                    color='primary'>
                    View Failed Experiences
                </Button>
            </div>
        </Modal>
    );
};
