import * as React from 'react';
import store from './redux/store';
import App from './components/App';
import Auth from './components/Auth';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Router, Route } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import './less/styles.less';

render(
    <Provider store={store}>
        <Router history={syncHistoryWithStore(browserHistory, store)}>
            <Route
                path='/auth'
                component={Auth}
            />
            <Route
                path='/(:story_id)'
                component={App}
            />
        </Router>
    </Provider>,
    document.getElementById('react-root')
);
