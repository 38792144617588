export const BATCHES_COPY: any = {
    sectionTitle: 'Batch History',
    renderAlert:
        'This will re-render any rows that are set to “rejected” below. If you want to re-render the entire batch, please use the duplicate button first and then run that as a new batch.',
    table: {
        createdHeader: 'Created',
        nameHeader: 'Name',
        rowsHeader: 'Total Rows',
        rowsRendered: 'Rows Rendered: ',
        progressHeader: 'Completion Status',
        publish: 'Publish',
        moderationStatus: 'Moderation Status'
    },
    moderationStatus: {
        pending: 'Pending: ',
        approved: 'Approved: ',
        rejected: 'Rejected: ',
        used: 'Used: ',
        vip: 'VIP: ',
        failed: 'Failed: ',
        unrendered: 'Unrendered: '
    },
    newMenu: {
        cta: 'Enter a name for your new batch',
        nameLabel: 'Batch Name'
    },
    tooltips: {
        tableAdd: 'Create a new batch',
        tableRefresh: 'Reload batches',
        tableDelete: 'Delete batch',
        tableEdit: 'Edit batch columns',
        tableTemplate: 'Download template .csv file',
        tableExport: 'Download batch to a .csv file',
        tableDuplicate: 'Duplicate this batch',
        tableImport: 'Upload a .csv file for this batch',
        tableRender: 'Render batch',
        tableCancelRender: 'Cancel rendering',
        tableShortcuts: 'Keyboard Shortcuts'
    },
    shortcuts: {
        'Shift + Up': 'Select previous batch',
        'Shift + Down': 'Select next batch',
        'Shift + Left': 'Go to previous page',
        'Shift + Right': 'Go to next page',
        'Alt + Shift + R': 'Refresh current page'
    },
    progress: {
        notRendered: 'not rendered',
        rendering: 'rendering',
        rendered: 'rendered',
        cancelled: 'cancelled',
        queued: 'queued',
        malformed: 'unknown',
        failed: 'failed'
    }
};

export const DATA_SET_COPY: any = {
    sectionTitle: 'Data Set',
    tooltips: {
        tableAddColumn: 'Add New Column',
        tableRefresh: 'Reload Data Set'
    },
    filterBarPlaceholder: 'Filter Data Set'
};

export const EXPERIENCES_COPY: any = {
    sectionTitle: 'Experiences',
    filterBarPlaceholder: 'Filter experiences',
    moderation: {
        pending: {
            label: 'Pending',
            moveTo: 'Move experience to pending bucket'
        },
        approved: {
            label: 'Approved',
            moveTo: 'Move experience to approved bucket'
        },
        rejected: {
            label: 'Rejected',
            moveTo: 'Move experience to rejected bucket'
        },
        used: {
            label: 'Used',
            moveTo: 'Move experience to used bucket'
        },
        vip: {
            label: 'VIP',
            moveTo: 'Move experience to vip bucket'
        },
        all: {
            label: 'All'
        }
    },
    table: {
        createdHeader: 'Created',
        durationHeader: 'Duration',
        aspectHeader: 'Aspect Ratio',
        moderationHeader: 'Moderation Status',
        renderingHeader: 'Render Status'
    },
    masks: {
        cta: 'Select table columns to display',
        toggleHide: 'Click to hide column',
        toggleShow: 'Click to show column'
    },
    tagNames: {
        moderationApproved: 'Moderation Status: Approved',
        moderationPending: 'Moderation Status: Pending',
        moderationRejected: 'Moderation Status: Rejected',
        moderationVIP: 'Moderation Status: VIP',
        moderationUsed: 'Moderation Status: Used',
        moderationFailed: 'Moderaton Status: Failed',
        experienceId: 'Experience Id: ',
        batchId: 'Batch Id: ',
        versionListId: 'Version List Id: ',
        creativeId: 'Creative Id: ',
        creativeLibraryId: 'Creative Library Id: '
    },
    tooltips: {
        tableNew: 'Create a new experience',
        tableColumns: 'Toggle table columns',
        tableRefresh: 'Reload experiences (shift + r)',
        tableExpanded: 'Show batches table',
        tableCollapsed: 'Hide batches table',
        downloadCSV: 'Download CSV',
        filterList: 'Show/Hide Failed Renders',
        tableShortcuts: 'Keyboard Shortcuts'
    },
    shortcuts: {
        'Up': 'Select previous experience',
        'Down': 'Select next experience',
        'Left': 'Go to previous page',
        'Right': 'Go to next page',
        'Shift + R': 'Refresh current page'
    }
};

export const ERROR: any = {
    download: `Failed to download from host server: [error].`
};

export const PREVIEWER_COPY: any = {
    sectionTitle: 'Experience Details',
    delete: 'Delete Experience',
    block: 'Reject + Ban User',
    deleteConfirmation:
        'WARNING! THIS ACTION CANNOT BE UNDONE. Deleting an experience will remove all record of the experience, including uploaded UGC data and media, as well as all output videos and images. The experience will no longer be accessable via the Imposium API or SDK. ARE YOU SURE YOU WANT TO PERMANENTLY DELETE [expId]?',
    blockConfirmation: `WARNING! THIS ACTION CANNOT BE UNDONE. By confirming this action, all experiences associated with this user's IP address will be immediately moved to REJECTED, and they will not be able to create any more while on the same IP address. ARE YOU SURE?`,
    output: {
        poster: 'Poster Frame',
        social: 'Social Frame',
        snapshot: 'Snapshot Frame',
        videoPrefix: 'Video',
        hls: 'HLS Manifest'
    },
    tooltips: {
        settings: 'Adjust preview settings',
        logs: 'View server logs for this experience',
        close: 'Close preview tab',
        shortcuts: 'Keyboard Shortcuts',
        delete: 'Delete Experience'
    },
    shortcuts: {
        P: 'Flag current experience as pending',
        A: 'Flag current experience as approved',
        U: 'Flag current experience as used',
        V: 'Flag current experience as VIP',
        R: 'Flag current experience as rejected'
    },
    metaFields: {
        createdLabel: 'Created',
        idLabel: 'Experience Id',
        compId: 'Composition Id',
        storyId: 'Story Id',
        moderationStatus: 'Moderation Status',
        notes: 'Notes',
        expJobLog: 'Job Log',
        expJobLogToolTips: 'Download Job Log'
    },
    playbackSettings: {
        cta: 'Preview Settings',
        rateLabel: 'Playback Rate',
        autoPlayLabel: 'Auto Play',
        loopLabel: 'Loop'
    }
};

export const MODERATION_COPY: any = {
    approved: 'Approved',
    pending: 'Pending',
    rejected: 'Rejected',
    used: 'Used',
    vip: 'VIP'
};

export const REJECTION_OPTIONS: any[] = [
    {
        category: 'Violence and Threats',
        content: [
            'Contains Threats to harm others, or organize acts of real-world violence.',
            'Contains Threats of self-harm or promotion or encouragement of self-mutilation.',
            'Suggests or encourages illegal activity.',
            'Contains content suggesting political affiliations.',
            'Contains Racial comments/affiliations including flags or symbols.'
        ]
    },
    {
        category: 'Adult/Obscene',
        content: [
            'Contains Obscene, profane, lewd, graphic or suggestive content.',
            'Exploits children or minors in any form.',
            'Contains sex toys in the frame.'
        ]
    },
    {
        category: 'Bullying and Harassment',
        content: [
            'Bullying or stalking, including abusive behavior directed at individuals.',
            'Harassment of others including but not limited to name-calling, taunting, teasing or threats.',
            'Contains Personal identifying information (phone #, address, SSN, etc.).'
        ]
    },
    {
        category: 'Hate Speech',
        content: [
            'Contains Racially motivated or otherwise offensive language.',
            'Attacks a person based on their race, ethnicity, national origin, religion, sex, gender, sexual orientation, disability or medical condition.'
        ]
    },
    {
        category: 'Illegal Drug Use',
        content: [
            'Describes or encourages illegal drug use.',
            'Describes underage drinking.',
            'Contains alcoholic beverages.',
            'Contains drug paraphernalia.'
        ]
    },
    {
        category: 'Graphic Content',
        content: [
            'Encourages or depicts cruelty to animals',
            'Contains gang signs.',
            'Contains racist content.'
        ]
    },
    {
        category: 'Other',
        content: ['Technical difficulties']
    }
];
