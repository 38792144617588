import { StorageService } from '@imposium-hub/components';

const SETTINGS_CACHE_KEY: string = 'exp_manager_playback_settings';

const actions: any = {
    UPDATE_SETTING: 'playbackSettings/UPDATE_SETTING'
};

const RATE_KEY: string = 'rate';
const AUTO_PLAY_KEY: string = 'autoPlay';
const SELECTED_VERSIONS_KEY: string = 'selectedVersions';
const LOOP_KEY: string = 'loop';

export const getCachedPlaybackSettings = (): any => {
    return (dispatch) => {
        StorageService.getItem(SETTINGS_CACHE_KEY)
            .then((playbackSettings) => {
                if (playbackSettings) {
                    dispatch({
                        type: actions.UPDATE_SETTING,
                        setting: RATE_KEY,
                        value: playbackSettings.rate
                    });
                    dispatch({
                        type: actions.UPDATE_SETTING,
                        setting: AUTO_PLAY_KEY,
                        value: playbackSettings.autoPlay
                    });
                    dispatch({
                        type: actions.UPDATE_SETTING,
                        setting: SELECTED_VERSIONS_KEY,
                        value: playbackSettings.selectedVersions || {}
                    });
                    dispatch({
                        type: actions.UPDATE_SETTING,
                        setting: LOOP_KEY,
                        value: playbackSettings.loop || false
                    });
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };
};

export const updatePlaybackSpeed = (rate: number): any => {
    return (dispatch, getStore) => {
        dispatch({ type: actions.UPDATE_SETTING, setting: RATE_KEY, value: rate });
        void StorageService.setItem(SETTINGS_CACHE_KEY, getStore().playbackSettings);
    };
};

export const toggleAutoPlay = (toggle: boolean): any => {
    return (dispatch, getStore) => {
        dispatch({ type: actions.UPDATE_SETTING, setting: AUTO_PLAY_KEY, value: toggle });
        void StorageService.setItem(SETTINGS_CACHE_KEY, getStore().playbackSettings);
    };
};
export const toggleLoop = (toggle: boolean): any => {
    return (dispatch, getStore) => {
        dispatch({ type: actions.UPDATE_SETTING, setting: LOOP_KEY, value: toggle });
        void StorageService.setItem(SETTINGS_CACHE_KEY, getStore().playbackSettings);
    };
};

export const setVersions = (versions: any): any => {
    return (dispatch, getStore) => {
        dispatch({ type: actions.UPDATE_SETTING, setting: SELECTED_VERSIONS_KEY, value: versions });
        void StorageService.setItem(SETTINGS_CACHE_KEY, getStore().playbackSettings);
    };
};

export default actions;
