import actions from '../actions/active-tab';

const activeTab = (state = 0, action): any => {
    if (action.type === actions.SET_ACTIVE_TAB) {
        return action.tabIndex;
    } else {
        return state;
    }
};

export default activeTab;
