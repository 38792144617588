import * as React from 'react';
import { HRule, CheckboxField } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleColumnMask } from '../../redux/actions/column-masks';
import { EXPERIENCES_COPY } from '../../constants/copy';

interface IColumnMaskMenuProps {
    columnMasks: any[];
    toggleColumnMask: (id: string, toggle: boolean) => any;
}

class ColumnMaskMenu extends React.Component<IColumnMaskMenuProps> {
    private static readonly INPUT_WIDTH: string = '50%';

    constructor(props: IColumnMaskMenuProps) {
        super(props);
    }

    public render = (): JSX.Element => {
        const { columnMasks } = this.props;
        const toggles: JSX.Element[] = columnMasks.map((maskConfig: any) => (
            <CheckboxField
                width={ColumnMaskMenu.INPUT_WIDTH}
                key={maskConfig.id}
                label={maskConfig.name}
                value={maskConfig.show}
                tooltip={
                    maskConfig.show
                        ? EXPERIENCES_COPY.masks.toggleHide
                        : EXPERIENCES_COPY.masks.toggleShow
                }
                onChange={(t) => this.props.toggleColumnMask(maskConfig.id, t)}
            />
        ));

        return (
            <div className='column-masks-menu'>
                <p>{EXPERIENCES_COPY.masks.cta}</p>
                <HRule />
                {toggles}
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ toggleColumnMask }, dispatch);
};

const mapStateToProps = (state): any => {
    return { columnMasks: state.columnMasks };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnMaskMenu);
