import actions from '../actions/panes';

const initialState: any = {
    tablePaneSize: '65%',
    previewPaneSize: 480,
    showBatchesTable: true,
    showPreviewer: true
};

const panes = (state = initialState, action): any => {
    switch (action.type) {
        case actions.HYDRATE:
            return { ...state, ...action.panesState };
        case actions.REPOSITION:
            return {
                ...state,
                [action.paneKey]: action.position
            };
        case actions.TOGGLE_PANE:
            return {
                ...state,
                [action.paneKey]: action.toggle
            };
        case actions.RESET:
            return { ...initialState };
        default:
            return state;
    }
};

export default panes;
