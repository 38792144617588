import * as React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateModerationStatus } from '../redux/actions/experiences';
import { toggleRejectMenu } from '../redux/actions/reject-menu';
import { REJECTION_OPTIONS } from '../constants/copy';

interface IRejectContextMenuProps {
    experiencesList: any;
    rejectMenu: boolean;
    toggleRejectMenu: (toggle: boolean) => any;
    updateModerationStatus: (experienceId: string, status: string, reason?: string) => any;
}

interface IRejectContextMenuState {
    mouseX: number;
    mouseY: number;
    menuOpenX: number;
    menuOpenY: number;
}

class RejectContextMenu extends React.Component<IRejectContextMenuProps, IRejectContextMenuState> {
    private static readonly ESC_CODE: number = 27;

    private static readonly MENU_WIDTH: number = 300;

    private static readonly MENU_HEIGHT: number = 400;

    private static readonly OVERFLOW_PADDING: number = 15;

    private static readonly MENU_DOM_NODE: HTMLElement = document.getElementById('reject-menu');

    private readonly OUTSIDE_CLICK_DELEGATE: any = (e) => this.detectOutsideClick(e);

    private readonly ESC_PRESS_DELEGATE: any = (e) => this.detectEscPressed(e);

    private readonly RESIZE_DELEGATE: any = () => this.onResize();

    private readonly MENU_REF: any;

    constructor(p: IRejectContextMenuProps) {
        super(p);

        this.state = {
            mouseX: 0,
            mouseY: 0,
            menuOpenX: 0,
            menuOpenY: 0
        };

        this.MENU_REF = React.createRef();
    }

    public componentDidMount = (): void => {
        window.addEventListener('mousemove', this.onMouseMove);
    };

    public componentDidUpdate = (prevProps: Readonly<IRejectContextMenuProps>): void => {
        if (this.props.rejectMenu && !prevProps.rejectMenu) {
            window.addEventListener('mousedown', this.OUTSIDE_CLICK_DELEGATE);
            window.addEventListener('keydown', this.ESC_PRESS_DELEGATE);
            window.addEventListener('resize', this.RESIZE_DELEGATE);

            this.setState({
                menuOpenX: this.state.mouseX,
                menuOpenY: this.state.mouseY
            });
        }

        if (!this.props.rejectMenu && prevProps.rejectMenu) {
            window.removeEventListener('mousedown', this.OUTSIDE_CLICK_DELEGATE);
            window.removeEventListener('keydown', this.ESC_PRESS_DELEGATE);
            window.removeEventListener('resize', this.RESIZE_DELEGATE);

            this.setState({
                menuOpenX: 0,
                menuOpenY: 0
            });
        }
    };

    private detectOutsideClick = (e): void => {
        if (this.MENU_REF.current && !this.MENU_REF.current.contains(e.target)) {
            this.props.toggleRejectMenu(false);
        }
    };

    private detectEscPressed = (e): void => {
        if (e.keyCode === RejectContextMenu.ESC_CODE) {
            this.props.toggleRejectMenu(false);
        }
    };

    private onResize = (): void => {
        this.props.toggleRejectMenu(false);
    };

    private onMouseMove = (e): void => {
        this.setState({
            mouseX: e.clientX,
            mouseY: e.clientY
        });
    };

    private doReject = (reason: string): void => {
        if (this.props.experiencesList.selected.id) {
            this.props.updateModerationStatus(
                this.props.experiencesList.selected.id,
                'rejected',
                reason
            );
        }
    };

    public render = (): JSX.Element => {
        const xOffset: number = this.state.menuOpenX + RejectContextMenu.MENU_WIDTH;
        const yOffset: number = this.state.menuOpenY + RejectContextMenu.MENU_HEIGHT;

        const style: React.CSSProperties = {
            top: this.state.menuOpenY,
            width: RejectContextMenu.MENU_WIDTH
        };

        if (xOffset < window.innerWidth) {
            style.left = this.state.menuOpenX;
        } else {
            style.left =
                this.state.menuOpenX -
                (xOffset - window.innerWidth) -
                RejectContextMenu.OVERFLOW_PADDING;
        }

        if (yOffset < window.innerHeight) {
            style.height = RejectContextMenu.MENU_HEIGHT;
        } else {
            style.height =
                RejectContextMenu.MENU_HEIGHT -
                (yOffset - window.innerHeight) -
                RejectContextMenu.OVERFLOW_PADDING;
        }

        const menu: JSX.Element = (
            <div
                ref={this.MENU_REF}
                className='reject-context-menu'
                style={style}>
                {Object.keys(REJECTION_OPTIONS).map((key) => (
                    <div
                        className='reject-category'
                        key={key}>
                        <h4>{REJECTION_OPTIONS[key].category}</h4>
                        <ul className='reject-reason-list'>
                            {REJECTION_OPTIONS[key].content.map((reason, i) => (
                                <li
                                    key={i}
                                    onClick={() => this.doReject(reason)}>
                                    {reason}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        );

        return createPortal(this.props.rejectMenu ? menu : null, RejectContextMenu.MENU_DOM_NODE);
    };
}

const mapStateToProps = (state): any => {
    return { experiencesList: state.experiencesList, rejectMenu: state.rejectMenu };
};

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateModerationStatus, toggleRejectMenu }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectContextMenu);
